import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ShareDataService } from './../../../service/share-data.service';
import { Dataservice } from '../../../core/auth';

@Component({
  selector: 'kt-add-gift-card',
  templateUrl: './add-gift-card.component.html',
  styleUrls: ['./add-gift-card.component.scss']
})
export class AddGiftCardComponent implements OnInit {
  brandId = localStorage.getItem('BrandID');
  accountId : any;
  configId : number
  getBalance :any;
  addAmount :any;
  isAddDeduct : any;
  // Spinner
  isLoading : boolean = false;
  alertMessage: any;
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dataService: Dataservice,
    private ref: ChangeDetectorRef,
    private shareData : ShareDataService,
  ) { }

  ngOnInit() {
    this.getBalance = this.data.balance
    this.accountId = this.data.accountId;
    this.configId = this.data.configId;
    this.isAddDeduct = this.data.isAddDeduct;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46 ) {
      return false;
    }
    return true;

  }
  submitAddBalance(){
    // https://dev-perkss.azurewebsites.net/api/GiftCard/proceedTransaction/1073535/10/Add/656
    this.isLoading = true;
    this.dataService.put("GiftCard/proceedTransaction/"+this.accountId+"/"+this.addAmount+"/"+this.isAddDeduct+"/"+this.configId ,[]).subscribe(
      res=>{
        this.isLoading = false;
        this.alertMessage = res["Info"]["Message"];
        alert(this.alertMessage)
      }
    )
    
  }

}
export interface DialogData {
  balance: any;
  configId : number,
  accountId : any;
  isAddDeduct : any;
}

