<div class="dialogue-body">
    <div class="row">
        <div class="col-xl-12">
            <kt-portlet [class]="'kt-portlet--height-fluid'" style=" overflow-x: hidden !important;">
                <h3 mat-dialog-title style="color: #616072;text-align: center;border-bottom: 1px solid #9497a7;padding: 15px 0px 15px 0px;">Add Recipe Options</h3>
                <kt-portlet-body style=" overflow-x: hidden !important;">
                    <form [formGroup]="addOptionForm">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">   
                                    <label for="exampleFormControlFile1">Image</label>
                                    <img [src]="url" width="100px" *ngIf="getImage">
                                    <input #file type="file" accept='image/*' class="form-control-file" id="exampleFormControlFile1" (change)="addFile($event,file.files)">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Title</label>
                                    <input type="text" formControlName="extraTitle"  class="form-control" placeholder="Enter Extra Title">
                                
                                </div>
                            </div>
                           
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Price</label>
                                    <input type="text" (keypress)="numberOnly($event)" 
                                    formControlName="extraPrice" class="form-control" 
                                    placeholder="Enter Extra Price">
                                </div>
                            </div>
                            
                            <div class="col-12">
                                <!-- Submit Button -->
                                <div class="row">

                                    <div class="col-6" align="right">
                                        <button type="submit" class="btn btn-primary btn-elevate kt-login__btn-primary" style="border-radius: 50px;" (click)="onSubmit()">Update</button>
                                    </div>
                                    <div class="col-6" align="left">
                                        <button mat-dialog-close type="submit" class="btn btn-primary btn-elevate kt-login__btn-primary" style="border-radius: 50px;" >Cancel</button>
                                    </div>
                                </div>
                                
                                
                                <!-- Submit Button End -->
                            </div>
                            
                        </div>
                    </form>
                </kt-portlet-body>
            </kt-portlet>
        </div>
    </div>
    </div>
    <div class="overlay" *ngIf="isLoading">
        <div class="center">
            <mat-spinner  [diameter]="200" ></mat-spinner>
        </div>
    </div>