<div mat-dialog-title  align="center" style="margin-top: 1%;">
    <h2> Add Balance</h2>
</div>

<mat-dialog-content class="mat-typography">
    <div class="container">
        
        <h1 class="form-group" align="center">
            £{{getBalance | number : '1.2-2'}}
            <small class="form-text text-muted">In Wallet</small>
        </h1>
        <div>
            <div class="form-group">
                <label for="units">Amount</label>
                <input type="text" (keypress)="numberOnly($event)" [(ngModel)]="addAmount" class="form-control" id="units" aria-describedby="unitHelp" placeholder="Enter Amount">
                <small style="color: red !important;" id="unitHelp" class="form-text text-muted">Just type Numbers/Decimal point</small>
            </div>
      
        </div>

    </div>
 

</mat-dialog-content>
<mat-dialog-actions align="center">
    <button class="btn btn-primary btn-elevate kt-login__btn-primary"  style="margin-left:5px;border-radius: 50px;" [mat-dialog-close]="true" cdkFocusInitial 
    (click)="submitAddBalance()">
        Add Balance
    </button>
     <!-- [mat-dialog-close]="true" cdkFocusInitial -->
    <button class="btn btn-primary btn-elevate kt-login__btn-primary" mat-dialog-close style="margin-left:5px;border-radius: 50px;">
        Cancel
    </button>
</mat-dialog-actions> 
<div class="overlay" *ngIf="isLoading">
    <div class="center">
        <mat-spinner [diameter]="200"></mat-spinner>
    </div>
</div>