import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ShareDataService } from './../../../service/share-data.service';
import { Dataservice } from '../../../core/auth';

@Component({
  selector: 'kt-deduct-balance',
  templateUrl: './deduct-balance.component.html',
  styleUrls: ['./deduct-balance.component.scss']
})
export class DeductBalanceComponent implements OnInit {
  brandId = localStorage.getItem('BrandID');
  getBalance :any;
  addAmount :any;
  // Spinner
  isLoading : boolean = false;
  alertMessage: any;
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dataService: Dataservice,
    private ref: ChangeDetectorRef,
    private shareData : ShareDataService,
  ) { }

  ngOnInit() {
    this.getBalance = this.data.balance
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46 ) {
      return false;
    }
    return true;

  }
  submitDeductBalance(){
    // https://perkssrestapidevelopment.azurewebsites.net/api/Wallet/addOrDeductBalance
    this.isLoading = true;
    // console.log(`this.addAmount ${this.addAmount}`);
    let params;
    params ={
      BrandId: this.brandId,
      CustomerId: this.shareData.customerId,
      Balance: this.addAmount,
      IsDeduct: true
    }
    this.dataService.post("Wallet/addOrDeductBalance",params).subscribe(
      res=>{
        // console.log(res);
        this.isLoading = true;
        this.alertMessage = res["Info"]["Message"];
        alert(this.alertMessage)
      }
    )
    
  }

}
export interface DialogData {
  balance: any;
}