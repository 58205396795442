// Angular
import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
// RxJS
import { Observable, Subject } from "rxjs";
import { finalize, takeUntil, tap } from "rxjs/operators";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Store
import { Store } from "@ngrx/store";
import { AppState } from "../../../../core/reducers";
// Auth
import {
	AuthNoticeService,
	AuthService,
	Login,
	Dataservice,
} from "../../../../core/auth";
import { AlertService } from "../../_alert";
import { LocationStrategy } from "@angular/common";

// Fire Base
// import { MessagingService } from '../../../../service/messaging.service';

// Template Service
import { GetTemplateDataService } from "../../../../service/templatedata/get-template-data.service";
import { environment } from "../../../../../environments/environment";

/**
 * ! Just example => Should be removed in development
 */
const DEMO_PARAMS = {
	EMAIL: "",
	PASSWORD: "",
};

@Component({
	selector: "kt-login",
	templateUrl: "./login.component.html",
	encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {
	// Public params
	loginForm: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];
	staticAlertClosed: Boolean;
	isNewlyLogin: boolean = true;
	printTemplate: any;
	private unsubscribe: Subject<any>;

	private returnUrl: any;
	private BrandId: any;
	private UserName: any;
	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	isDevelopment: boolean;
	// Fire Base
	message: any;

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private dataService: Dataservice,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private alertService: AlertService,
		private location: LocationStrategy,
		// private messagingService: MessagingService,
		private templateData: GetTemplateDataService
	) {
		this.unsubscribe = new Subject();
		this.staticAlertClosed = false;

		history.pushState(null, null, window.location.href);
		this.location.onPopState(() => {
			history.pushState(null, null, window.location.href);
		});
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.isDevelopment = !environment.production;
		this.dataService.isNewlyLogin = this.isNewlyLogin;

		//debugger;
		this.initLoginForm();

		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe((params) => {
			this.returnUrl = params.returnUrl || "/";
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next(null);
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initLoginForm() {
		// demo message to show
		if (!this.authNoticeService.onNoticeChanged$.getValue()) {
			const initialNotice = `Use account
			<strong>${DEMO_PARAMS.EMAIL}</strong> and password
			<strong>${DEMO_PARAMS.PASSWORD}</strong> to continue.`;
			this.authNoticeService.setNotice(initialNotice, "info");
		}

		this.loginForm = this.fb.group({
			email: [
				DEMO_PARAMS.EMAIL,
				Validators.compose([
					Validators.required,
					//Validators.email,
					Validators.minLength(3),
					Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
				]),
			],
			password: [
				DEMO_PARAMS.PASSWORD,
				Validators.compose([
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(100),
				]),
			],
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		// debugger;
		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach((controlName) =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		const authData = {
			email: controls.email.value,
			password: controls.password.value,
		};
		controls.email.disable();
		controls.password.disable();
		this.auth
			.login(authData.email, authData.password)
			.pipe(
				tap((user) => {
					if (user) {
						//this.dataService.
						this.store.dispatch(
							new Login({ authToken: user.access_token })
						);
						localStorage.setItem("token", user.access_token);
						localStorage.setItem("userName", user.userName);
						this.templateData.getMerchantData(user.userName);
						this.getBrandID();
						// Login Fire base
						// this.messagingService.requestPermission()
						// console.log("Main receiveMessage called")
						// this.messagingService.receiveMessage()
						// this.message = this.messagingService.currentMessage
						// Login Fire base
						// Main page
					} else {
						this.authNoticeService.setNotice(
							this.translate.instant(
								"AUTH.VALIDATION.INVALID_LOGIN"
							),
							"danger"
						);
						this.error(
							this.translate.instant(
								"AUTH.VALIDATION.INVALID_LOGIN"
							)
						);
						controls.email.enable();
						controls.password.enable();
					}
				}),
				takeUntil(this.unsubscribe),
				finalize(() => {
					this.loading = false;
					this.cdr.markForCheck();
				})
			)
			.subscribe(),
			(err) => {
				//debugger;
				this.authNoticeService.setNotice(err.error.Message, "danger");
				controls.email.enable();
				controls.password.enable();
				//this.error(err.error.Message);
			};
	}
	getOrderConfig(val) {
		// console.log("val----");
		// console.log(val);
		// https://dev-perkss.azurewebsites.net/api/Order/v1/getConfig/1350
		this.dataService
			.get("Order/v1/getConfig/" + val, [])
			.subscribe((res, next) => {
				localStorage.setItem(
					"OrderConfigSettings",
					JSON.stringify(res["Data"])
				);
				// next();
			});
	}
	getBrandID() {
		this.dataService
			.get(
				"/Merchant/getMerchant/" + localStorage.getItem("userName"),
				[]
			)
			.subscribe((data) => {
				// debugger;
				this.BrandId = data.Data.Brand.BrandId;
				this.UserName = data.Data.Brand.UserName;
				localStorage.setItem("BrandID", this.BrandId);
				this.getOrderConfig(this.BrandId);
				localStorage.setItem("MerchantId", data.Data.MerchantId);
				localStorage.setItem("BrandName", data.Data.Brand.BrandName);
				localStorage.setItem(
					"BrandFlavor",
					data.Data.Brand.BrandFlavor
				);
				localStorage.setItem(
					"BrandBranchLogo",
					data.Data.Brand.BrandBranchLogo
				);
				localStorage.setItem(
					"IsCustomerInfo",
					data.Data.Brand.Settings.IsCustomerInfo
				);
				localStorage.setItem(
					"CheckInByUnit",
					data.Data.Brand.Settings.CheckInByUnit
				);
				localStorage.setItem(
					"CheckInByVisit",
					data.Data.Brand.Settings.CheckInByVisit
				);
				localStorage.setItem(
					"UnitMaxLimit",
					data.Data.Brand.Settings.UnitMaxLimit
				);
				localStorage.setItem(
					"IsLoyaltyReference",
					data.Data.Brand.Settings.IsLoyaltyReference
				);
				localStorage.setItem(
					"IsCustomerInfoForRedemption",
					data.Data.Brand.Settings.IsCustomerInfoForRedemption
				);

				localStorage.setItem(
					"IsMarketingEmail",
					data.Data.Brand.MerketingPreferences.IsMarketingEmail
				);
				localStorage.setItem(
					"IsMarketingSms",
					data.Data.Brand.MerketingPreferences.IsMarketingSms
				);
				localStorage.setItem(
					"IsMarketingPushNotification",
					data.Data.Brand.MerketingPreferences
						.IsMarketingPushNotification
				);

				localStorage.setItem(
					"LoyaltyAccountId",
					data.Data.LoyaltyAccount.Id
				);
				localStorage.setItem(
					"CanGivePointsByUnit",
					data.Data.FeaturePermissions.CanGivePointsByUnit
				);
				localStorage.setItem(
					"CanGivePointsByVisit",
					data.Data.FeaturePermissions.CanGivePointsByVisit
				);
				//console.log(data.Data);
				//this.router.navigateByUrl(this.returnUrl);
				localStorage.setItem("BusyMode", "False");
				this.router
					.navigate(["/advancesearch"])
					.then((success) =>
						console.log("navigation success?", success)
					)
					.catch(console.error);
			});
		
	}

	// getTemplateConfig(){

	// }

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}

	success(message: string) {
		this.alertService.success(message);
		setTimeout(() => (this.staticAlertClosed = true), 5000);
	}
	error(message: string) {
		this.alertService.error(message);
		setTimeout(() => (this.staticAlertClosed = true), 5000);
	}
	reloadCurrentRoute() {
		let currentUrl = this.router.url;
		this.router
			.navigateByUrl("/", { skipLocationChange: true })
			.then(() => {
				this.router.navigate([currentUrl]);
			});
	}
}
