<div
	*ngIf="user$ | async as _user"
	class="d-flex flex-column justify-content-center"
>
	<span class="kt-header__topbar-username kt-hidden-mobile" *ngIf="greeting">
		<p class="text-center text-dark fw-bold mb-1">{{ UserName }}</p>
	</span>
	<button
		(click)="logout()"
		class="btn btn-outline-brand btn-upper btn-sm btn-bold sign-out-btn"
	>
		Sign Out
	</button>
</div>
