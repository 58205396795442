import { Component, OnInit, ChangeDetectorRef} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Dataservice } from '../../../core/auth';
import { ShareDataService } from './../../../service/share-data.service';

@Component({
  selector: 'kt-edit-postcode',
  templateUrl: './edit-postcode.component.html',
  styleUrls: ['./edit-postcode.component.scss']
})
export class EditPostcodeComponent implements OnInit {
  brandFlavor = localStorage.getItem('BrandFlavor');
  editPostcodeForm : FormGroup;

  postCodeData :any;
  radiusData :any;

  // Spinner
  isLoading : boolean = false;
  alertMessage: any;

  // Boolean span
  status: boolean = false;

  postcodeValue :number

  constructor(
    private dataService: Dataservice,
    private ref: ChangeDetectorRef,
    private router : Router,
    private sharedata : ShareDataService
  ) { }

  ngOnInit() {
    this.editPostcodeForm = new FormGroup({
      prefix : new FormControl(),
      suffix : new FormControl(),
      deliveryCharges : new FormControl(),
      minimumOrder : new FormControl(),
      chargesBelow : new FormControl(),
      freeCharges : new FormControl()
    });

    this.postcodeValue = this.sharedata.postcodeId;

    this.isLoading = true;
    this.dataService.get("Config/getPostcodes/"  + this.brandFlavor , []).subscribe(
      res =>{
        if(res["Info"]["Status"] == 200){
          this.postCodeData = res["Postcodes"];
          this.radiusData = res["PostcodeRadiusConfig"];
          this.postCodeData.forEach((obj,index) => {
            obj.PostcodeId = index
          });
    
          this.radiusData.forEach((obj,index) => {
            obj.RadiusId = index
          });
          // 
          this.postCodeData.forEach(obj => {
            if(obj.PostcodeId == this.postcodeValue){
              this.editPostcodeForm.controls['prefix'].setValue(obj.PostcodePrefix);
              this.editPostcodeForm.controls['suffix'].setValue(obj.PostcodeSuffix);
              this.editPostcodeForm.controls['deliveryCharges'].setValue(obj.DeliveryCharges);
              this.editPostcodeForm.controls['minimumOrder'].setValue(obj.MinimumOrderAmount);
              this.editPostcodeForm.controls['chargesBelow'].setValue(obj.ChargesBelowMinimumOrderAmount);
              this.editPostcodeForm.controls['freeCharges'].setValue(obj.FreeChargesAbove);
              this.status = obj.IsActive
            }
          });
          // 
          this.isLoading = false;
        }
        else{
          this.alertMessage = res["Info"]["Message"];
          this.isLoading = false;
          alert(this.alertMessage);
        }
        this.ref.detectChanges();

      }
    );

  }
  changeStatus(value: boolean){
    this.status = value;
    console.log(`this.status : ${this.status}`)
  }
  setValues(){
    this.postCodeData.forEach(obj => {
      if(obj.PostcodeId == this.postcodeValue){
        console.log("found");
        // this.postCodeData = this.postCodeData.filter(PostcodeId => PostcodeId !== value);
        // delete obj.postcodeId;
        this.postCodeData.splice(this.postcodeValue, 1);
      }
    });
    console.log(this.postCodeData.length);
    // Remove Id
    this.postCodeData.forEach((obj,index) => {
      delete obj.PostcodeId;
    });

    this.radiusData.forEach((obj,index) => {
      delete obj.RadiusId;
    });

    // Remove Id Ends

    


    let params; 
    params = {
      PostcodePrefix : this.editPostcodeForm.get('prefix').value,
      PostcodeSuffix : this.editPostcodeForm.get('suffix').value,
      DeliveryCharges : Number(this.editPostcodeForm.get('deliveryCharges').value),
      MinimumOrderAmount : Number(this.editPostcodeForm.get('minimumOrder').value),
      ChargesBelowMinimumOrderAmount : Number(this.editPostcodeForm.get('chargesBelow').value),
      FreeChargesAbove : Number(this.editPostcodeForm.get('freeCharges').value),
      IsActive : this.status
    }
    this.postCodeData.push(params);
    params = {
      PostcodeConfigType : true,
      Postcodes : this.postCodeData,
      PostcodeRadiusConfig : this.radiusData
    }
   this.isLoading = true;
   this.dataService.put("Config/updatePostcodes/"  + this.brandFlavor , params).subscribe(
     res =>{
       if(res["Info"]["Status"] == 200){
         // console.log(this.postCodeData)
         this.alertMessage = res["Info"]["Message"];
         this.isLoading = false;
         // alert(this.alertMessage);
       }
       else{
         this.alertMessage = res["Info"]["Message"];
         this.isLoading = false;
         alert(this.alertMessage);
       }
       this.ref.detectChanges();

     });
     this.reloadCurrentRoute();
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }

}
