<h2 mat-dialog-title style="text-align: center;padding-top: 3%;">Edit Radius</h2>
<mat-dialog-content class="mat-typography">
    <div class="container" style="padding: 8%;padding-top: 0%;
    padding-bottom: 0%;">
        <form [formGroup]="editRadiusForm">
            <div class="form-group row">
              <label for="fromdistance" class="col-sm-4 col-form-label">From Distance</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="fromDistance" id="fromdistance" placeholder="Enter From Distance">
              </div>
            </div>
            <div class="form-group row">
              <label for="todistance" class="col-sm-4 col-form-label">To Distance</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="toDistance" id="todistance" placeholder="Enter To Distance">
              </div>
            </div>
            <div class="form-group row">
                <label for="deliverycharges" class="col-sm-4 col-form-label">Delivery Charges (£)</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="deliveryCharges" id="deliverycharges" placeholder="Enter Delivery Charges">
                </div>
            </div>
            <div class="form-group row">
                <label for="minimumorder" class="col-sm-4 col-form-label">Minimum Order Amount (£)</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" id="minimumorder" formControlName="minimumOrder" placeholder="Enter Minimum Order">
                </div>
            </div>
            <div class="form-group row">
                <label for="chargesbelow" class="col-sm-4 col-form-label">Charges Below Minimum Order Amount (£)</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" id="chargesbelow" formControlName="chargesBelow"  placeholder="Enter Charges Below Minimum Order Amount">
                </div>
            </div>
            <div class="form-group row">
                <label for="freecharges" class="col-sm-4 col-form-label">Free Charges Above (£)</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" id="freecharges" formControlName="freeCharges" placeholder="Enter Free Charges For Order Amount">
                </div>
            </div>
            <div class="form-group row">
                <label for="freecharges" class="col-sm-4 col-form-label">Apply Extra Charges</label>
                <div class="col-sm-8">
                    <label class="switch" *ngIf="!isExtra">
                        <input type="checkbox" >
                        <span class="slider round" (click)="applyExtra(true)"></span>
                    </label>
                    <label class="switch" *ngIf="isExtra">
                        <input type="checkbox" checked>
                        <span class="slider round" (click)="applyExtra(false)"></span>
                    </label>
                    <small style="color: red;" id="freecharges" class="form-text text-muted">If turned ON, extra charges will be applied. Turn this OFF if you don't want to apply extra charges on this radius</small>
                </div>        
            </div>

            <div class="form-group row">
                <label for="freecharges" class="col-sm-4 col-form-label">Status (Active/InActive)</label>
                <div class="col-sm-8">
                    <label class="switch" *ngIf="!status">
                        <input type="checkbox" >
                        <span class="slider round" (click)="changeStatus(true)"></span>
                    </label>
                    <label class="switch" *ngIf="status">
                        <input type="checkbox" checked>
                        <span class="slider round" (click)="changeStatus(false)"></span>
                    </label>
                    <small style="color: red;" id="freecharges" class="form-text text-muted">If turned ON, this Radius will be applied. Turn this OFF if you want this Radius to be InActive</small>
                </div>        
            </div>
           
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button class="btn btn-primary btn-elevate kt-login__btn-primary"  style="margin-left:5px;border-radius: 50px;" [mat-dialog-close]="true" cdkFocusInitial (click)="setValues()">
        Update Radius
    </button>
     <!-- [mat-dialog-close]="true" cdkFocusInitial -->
    <button class="btn btn-primary btn-elevate kt-login__btn-primary" mat-dialog-close style="margin-left:5px;border-radius: 50px;">
        Cancel
    </button>
   
   
</mat-dialog-actions>
<div class="overlay" *ngIf="isLoading">
    <div class="center">
        <mat-spinner  [diameter]="200" ></mat-spinner>
    </div>
</div>
