<div class="row">
  <div class="col-xl-12">
    <a style="margin-right: 5px; margin-top: 5px;" class="close" (click)="onNoClick()"><b style=color:black;>
      <i class="material-icons">close</i></b></a>
    <br />
    <hr />

    <div class="form-group">
      <div class="row">
        <div class="col-sm-2"></div>
        <div class="col-sm-8">
          <br />
          <p style="color:crimson; margin: 0 auto;">{{modal_content}}</p>
        </div>
        <div class="col-sm-2">

        </div>
        <div style="clear:both;"></div>
      </div>
    </div>

    <div class="form-group">
      <div class="row">
          <div class="col-sm-2"></div>
          <div class="col-sm-4">
          </div>
          <div class="col-sm-2">
              <button mat-raised-button (click)="onNoClick()">OK</button>
          </div>
          <div class="col-sm-2">

          </div>
          <div style="clear:both;"></div>
      </div>
  </div>



  </div>
</div>