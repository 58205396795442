import { Component, OnInit, ChangeDetectorRef} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Dataservice } from '../../../core/auth';

@Component({
  selector: 'kt-add-postcode',
  templateUrl: './add-postcode.component.html',
  styleUrls: ['./add-postcode.component.scss']
})
export class AddPostcodeComponent implements OnInit {
  brandFlavor = localStorage.getItem('BrandFlavor');
  addPostcodeForm : FormGroup;

  postCodeData :any;
  radiusData :any;

  // Spinner
  isLoading : boolean = false;
  alertMessage: any;

  // Boolean span
  status: boolean = false;

  constructor(
    private dataService: Dataservice,
    private ref: ChangeDetectorRef,
    private router : Router
  ) { }

  ngOnInit() {
    this.addPostcodeForm = new FormGroup({
      prefix : new FormControl(),
      suffix : new FormControl(),
      deliveryCharges : new FormControl(),
      minimumOrder : new FormControl(),
      chargesBelow : new FormControl(),
      freeCharges : new FormControl()
    });

    this.isLoading = true;
    this.dataService.get("Config/getPostcodes/"  + this.brandFlavor , []).subscribe(
      res =>{
        if(res["Info"]["Status"] == 200){
          this.postCodeData = res["Postcodes"];
          this.radiusData = res["PostcodeRadiusConfig"]
          // console.log(this.postCodeData)
          this.isLoading = false;
        }
        else{
          this.alertMessage = res["Info"]["Message"];
          this.isLoading = false;
          alert(this.alertMessage);
        }
        this.ref.detectChanges();

      });
      this.ref.detectChanges();
  }
  changeStatus(value: boolean){
    this.status = value;
    console.log(`this.status : ${this.status}`)
  }
  setValues(){
    let params;
    params = {
      PostcodePrefix : this.addPostcodeForm.get('prefix').value,
      PostcodeSuffix : this.addPostcodeForm.get('suffix').value,
      DeliveryCharges : Number(this.addPostcodeForm.get('deliveryCharges').value),
      MinimumOrderAmount : Number(this.addPostcodeForm.get('minimumOrder').value),
      ChargesBelowMinimumOrderAmount : Number(this.addPostcodeForm.get('chargesBelow').value),
      FreeChargesAbove : Number(this.addPostcodeForm.get('freeCharges').value),
      IsActive : this.status
    }
    console.log("parmas")
    console.log(params)
    this.postCodeData.push(params);
    console.log("this.postCodeData")
    console.log(this.postCodeData)

    // 
    params = {
      PostcodeConfigType : true,
      Postcodes : this.postCodeData,
      PostcodeRadiusConfig : this.radiusData
    }
    this.isLoading = true;
    this.dataService.put("Config/updatePostcodes/"  + this.brandFlavor , params).subscribe(
      res =>{
        if(res["Info"]["Status"] == 200){
          // console.log(this.postCodeData)
          this.alertMessage = res["Info"]["Message"];
          this.isLoading = false;
          alert(this.alertMessage);
        }
        else{
          this.alertMessage = res["Info"]["Message"];
          this.isLoading = false;
          alert(this.alertMessage);
        }
        this.ref.detectChanges();

      });
      this.reloadCurrentRoute();
      
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }

}
