// Angular
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PopUpModalcomponentComponent } from '../../../../pages/pop-up-modalcomponent/pop-up-modalcomponent.component';
import { MatDialog } from '@angular/material/dialog';
import { DatasharingService } from '../../../../pages/loyaltyprofile/datasharing.service';
import { Dataservice } from '../../../../../core/auth';


@Component({
	selector: 'kt-search-dropdown',
	templateUrl: './search-dropdown.component.html',
})
export class SearchDropdownComponent implements OnInit {
	// Public properties

	// Set icon class name
	@Input() icon = 'flaticon2-search-1';

	// Set true to icon as SVG or false as icon class
	@Input() useSVG: boolean;

	@Input() type: 'brand' | 'success' | 'warning' = 'success';

	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;

	data: any[];
	result: any[];
	loading: boolean;
	loyaltyResult : Info;
	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	constructor(private dataService: Dataservice,private cdr: ChangeDetectorRef , private router: Router , public dialog: MatDialog, private dataSharingService: DatasharingService ) {
	}

	/**
	 * On init
	 */
	ngOnInit(): void {
		// simulate result from API
		// type 0|1 as separator or item
		this.result = [
			{
				text: 'Documents',
				type: 0
			}, {
				icon: '<i class="flaticon-interface-3 kt-font-warning">',
				text: 'Annual finance report',
				type: 1
			}, {
				icon: '<i class="flaticon-share kt-font-success"></i>',
				text: 'Company meeting schedule',
				type: 1
			}, {
				icon: '<i class="flaticon-paper-plane kt-font-info"></i>',
				text: 'Project quotations',
				type: 1
			}, {
				text: 'Customers',
				type: 0
			}, {
				img: '<img src="assets/media/users/user1.jpg" alt="">',
				text: 'Amanda Anderson',
				type: 1
			}, {
				img: '<img src="assets/media/users/user2.jpg" alt="">',
				text: 'Kennedy Lloyd',
				type: 1
			}, {
				img: '<img src="assets/media/users/user3.jpg" alt="">',
				text: 'Megan Weldon',
				type: 1
			}, {
				img: '<img src="assets/media/users/user4.jpg" alt="">',
				text: 'Marc-André ter Stegen',
				type: 1
			}, {
				text: 'Files',
				type: 0
			}, {
				icon: '<i class="flaticon-lifebuoy kt-font-warning"></i>',
				text: 'Revenue report',
				type: 1
			}, {
				icon: '<i class="flaticon-coins kt-font-primary"></i>',
				text: 'Anual finance report',
				type: 1
			}, {
				icon: '<i class="flaticon-calendar kt-font-danger"></i>',
				text: 'Tax calculations',
				type: 1
			}
		];
	}

	/**
	 * Search
	 * @param e: Event
	 */
	search(QRValue) {
		// this.data = null;
		// if (e.target.value.length > 2) {
		// 	this.loading = true;
		// 	// simulate getting search result
		// 	setTimeout(() => {
		// 		this.data = this.result;
		// 		this.loading = false;
		// 		this.cdr.markForCheck();
		// 	}, 500);
		// }

		this.dataService.get("/LoyaltyAccountHolder/getCardInfo/" + localStorage.getItem('BrandID') + "/" + QRValue + "", []).subscribe((data) => {
			//debugger;
			this.loyaltyResult = data.Info;
			console.log(this.loyaltyResult);
			if (this.loyaltyResult.Status === 200) {
			  this.router.navigate(['/loyaltyprofile'], { queryParams: { QR: QRValue } })
				.then(success => console.log('navigation success?', success))
				.catch(console.error);
			}
			else {
			  //this.error(this.loyaltyResult.Message);
			  alert(this.loyaltyResult.Message);
			  //this.openDialog(this.loyaltyResult.Message);
			}
		  });

	}


	openDialog(message): void {
		this.dataSharingService.AlertMessage = message;
		const dialogRef = this.dialog.open(PopUpModalcomponentComponent, {
		  width: '300px',
		  height: '200px',
		});
	
		dialogRef.afterClosed().subscribe(res => {
		  //this.color = res;
		});
	  }



	/**
	 * Clear search
	 *
	 * @param e: Event
	 */
	clear(e) {
		this.data = null;
		this.searchInput.nativeElement.value = '';
	}

	openChange() {
		setTimeout(() => this.searchInput.nativeElement.focus());
	}
}

export interface Info {
	Status: number;
	IsLoyaltyAccountOffer: boolean;
	NoOfLoyaltyAccountOffers: number;
	Message: string;
  }