import { ProductsListComponent } from './../../../views/pages/apps/e-commerce/products/products-list/products-list.component';
import { Type } from './../../../views/pages/loyaltyprofile/loyaltyprofile.component';
// import { CarsTable } from './cars.table';
import { Dataservice } from '../../auth';
// import { HttpHeaders } from '@angular/common/http';
// import { flatMap } from 'lodash';

export class CarsTable {

	requestedReservation: any[] = [];
	bookedReservation: any[] = [];
	declinedReservation: any[] = [];
	cancelledReservation: any[] = [];

	public static cars: any[] = []; 

	brandId: string;
	isDone: boolean = false;

	constructor(private dataService: Dataservice) { 
		this.brandId = localStorage.getItem("BrandID");
	}

	getBrandReservations(dateToken: string = 'Today'){
		// CarsTable.cars = [];
		let param = {
		  Token: dateToken
		}
	
		this.dataService.post('Reservation/get/'+this.brandId+'/0/20',JSON.stringify(param)).subscribe((data)=>{
		  if(data['Info']['Status']==200){
			  if(data['Info']['Message'] != 'No Booking Available'){
				console.log('getBrandReservations -> All -> '+JSON.stringify(data));
	
				if(data['Data']['Requested'].length > 0){
					this.requestedReservation = data['Data']['Requested'];
				}
	
				this.bookedReservation = data['Data']['Booked'];
				this.declinedReservation = data['Data']['Declined'];
				this.cancelledReservation = data['Data']['Cancelled'];
				console.log('BOOKED3');
				
				console.log('getBrandReservations -> Requested -> '+JSON.stringify(this.requestedReservation));
	
				this.setLists();
				console.log('mainList -> '+JSON.stringify(CarsTable.cars));
			  }
			  else{
				alert('No Booking available '+dateToken);
				CarsTable.cars = [];
			  }
		  }
		  else{
			alert('Something went wrong! Please refresh page.');
		  }
		});
	}

	updateReservation(status: string , reservationId: number){
		this.dataService.put('Reservation/update/'+reservationId+'/'+status,[]).subscribe((data)=>{
		  if(data['Info']['Status']==201){
			  this.getBrandReservations();
			  console.log('BOOKED2');
			  this.isDone = true;
			  window.location.reload();
		  }
		  else{
			alert('You have used status same as previous.');
			this.isDone = false;
		  }
		});
	}

	setLists(){
		console.log('BOOKED4');
		let date: string = '';
		let time: string = '';

		// CarsTable.cars = [];

		if(this.requestedReservation!=null){
			this.requestedReservation.forEach(val => {
				date = val.BookingTime;
				date = date.substring(0,10);
				time = this.getTime(val.BookingTime);
				let param = {
					id: val.Id,
					name: val.Customer.CustomerFirstName + val.Customer.CustomerLastName,
					phone: val.Customer.CustomerMobile,
					email: val.Customer.CustomerEmail,
					guests: val.Guests,
					date: date,
					time: time,
					status: 0
				}
				CarsTable.cars.push(param);
				console.log('getBrandReservations -> requestParam -> '+JSON.stringify(param));
			});
		}
		if(this.bookedReservation!=null){
			this.bookedReservation.forEach(val => {
				date = val.BookingTime;
				date = date.substring(0,10);
				time = this.getTime(val.BookingTime);
				let param = {
					id: val.Id,
					name: val.Customer.CustomerFirstName + val.Customer.CustomerLastName,
					phone: val.Customer.CustomerMobile,
					email: val.Customer.CustomerEmail,
					guests: val.Guests,
					date: date,
					time: time,
					status: 1
				}
				CarsTable.cars.push(param);
				console.log('getBrandReservations -> bookParam -> '+JSON.stringify(param));
			});
		}
		if(this.declinedReservation!=null){
			this.declinedReservation.forEach(val => {
				date = val.BookingTime;
				date = date.substring(0,10);
				time = this.getTime(val.BookingTime);
				let param = {
					id: val.Id,
					name: val.Customer.CustomerFirstName + val.Customer.CustomerLastName,
					phone: val.Customer.CustomerMobile,
					email: val.Customer.CustomerEmail,
					guests: val.Guests,
					date: date,
					time: time,
					status: 2
				}
				CarsTable.cars.push(param);
				console.log('getBrandReservations -> declineParam -> '+JSON.stringify(param));
			});
		}
		if(this.cancelledReservation!=null){
			this.cancelledReservation.forEach(val => {
				date = val.BookingTime;
				date = date.substring(0,10);
				time = this.getTime(val.BookingTime);
				let param = {
					id: val.Id,
					name: val.Customer.CustomerFirstName + val.Customer.CustomerLastName,
					phone: val.Customer.CustomerMobile,
					email: val.Customer.CustomerEmail,
					guests: val.Guests,
					date: date,
					time: time,
					status: 3
				}
				CarsTable.cars.push(param);
				console.log('getBrandReservations -> cancelParam -> '+JSON.stringify(param));
			});
		}
	}

	getTime(time: string){
		let hours: number = JSON.parse(time.substring(11,13));
		let timeType: string = '';
		let fullTime: string = '';

		if(hours > 12){
			hours -= 12;
			timeType = 'PM';
		}
		else{
			timeType = 'AM';
		}
		if(hours < 10){
			fullTime = '0'+hours+':00 '+timeType;
		}
		else{
			fullTime = hours+':00 '+timeType;
		}
		return fullTime;
	}

	// public static cars: any[] = [
	// 	// {
	// 	// 	id: 1,
	// 	// 	name: 'salman',
	// 	// 	phone: '03451600756',
	// 	// 	email: 'salman@gmail.com',
	// 	// 	guests: 5,
	// 	// 	date: '06-08-2020',
	// 	// 	time: '06:00 PM',
	// 	// 	status: 0
	// 	// }, 
	// 	// {
	// 	// 	id: 2,
	// 	// 	name: 'salman',
	// 	// 	phone: '03451600756',
	// 	// 	email: 'salman@gmail.com',
	// 	// 	guests: 5,
	// 	// 	date: '06-08-2020',
	// 	// 	time: '06:00 PM',
	// 	// 	status: 1
	// 	// }, 
	// 	// {
	// 	// 	id: 3,
	// 	// 	name: 'salman',
	// 	// 	phone: '03451600756',
	// 	// 	email: 'salman@gmail.com',
	// 	// 	guests: 5,
	// 	// 	date: '06-08-2020',
	// 	// 	time: '06:00 PM',
	// 	// 	status: 2
	// 	// }, 
	// 	// {
	// 	// 	id: 4,
	// 	// 	name: 'salman',
	// 	// 	phone: '03451600756',
	// 	// 	email: 'salman@gmail.com',
	// 	// 	guests: 5,
	// 	// 	date: '06-08-2020',
	// 	// 	time: '06:00 PM',
	// 	// 	status: 3
	// 	// }, 
	// 	// {
	// 	// 	id: 5,
	// 	// 	name: 'salman',
	// 	// 	phone: '03451600756',
	// 	// 	email: 'salman@gmail.com',
	// 	// 	guests: 5,
	// 	// 	date: '06-08-2020',
	// 	// 	time: '06:00 PM',
	// 	// 	status: 0
	// 	// },  
	// 	// {
	// 	// 	id: 6,
	// 	// 	name: 'salman',
	// 	// 	phone: '03451600756',
	// 	// 	email: 'salman@gmail.com',
	// 	// 	guests: 5,
	// 	// 	date: '06-08-2020',
	// 	// 	time: '06:00 PM',
	// 	// 	status: 0
	// 	// },  
	// 	// {
	// 	// 	id: 7,
	// 	// 	name: 'salman',
	// 	// 	phone: '03451600756',
	// 	// 	email: 'salman@gmail.com',
	// 	// 	guests: 5,
	// 	// 	date: '06-08-2020',
	// 	// 	time: '06:00 PM',
	// 	// 	status: 0
	// 	// },  
	// 	// {
	// 	// 	id: 8,
	// 	// 	name: 'salman',
	// 	// 	phone: '03451600756',
	// 	// 	email: 'salman@gmail.com',
	// 	// 	guests: 5,
	// 	// 	date: '06-08-2020',
	// 	// 	time: '06:00 PM',
	// 	// 	status: 1
	// 	// }, 
	// 	// {
	// 	// 	id: 34,
	// 	// 	model: 'Envoy XUV',
	// 	// 	manufacture: 'GMC',
	// 	// 	modelYear: 2004,
	// 	// 	mileage: 187960,
	// 	// 	tslint:disable-next-line:max-line-length
	// 	// 	description: `The GMC Envoy is a mid-size SUV that was produced by General Motors. It was introduced for the 1998 model year. After the first generation Envoy was discontinued after the 2000 model year, but the Envoy was reintroduced and redesigned for the 2002 model year, and it was available in the GMC line of vehicles from the 2002 to 2009 model years`,
	// 	// 	color: 'Turquoise',
	// 	// 	price: 185103,
	// 	// 	condition: 1,
	// 	// 	createdDate: '12/07/2017',
	// 	// 	status: 0,
	// 	// 	VINCode: '5GAER23D09J658030',
	// 	// 	_userId: 2,
	// 	// 	_createdDate: '12/08/2017',
	// 	// 	_updatedDate: '06/15/2011'
	// 	// }
	// ];
}
