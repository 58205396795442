<div mat-dialog-title  align="center" style="margin-top: 1%;">
    <h2> Email To notify the customer</h2>
</div>

<div class="mat-typography">
    <div class="container">
        
         
        <form>
            <div class="form-group">
                <label for="units">Email</label> 
                <!-- <input type="text"  class="form-control" id="units" aria-describedby="unitHelp" [(ngModel)]="customerEmail" placeholder="Enter Customer Email"> -->
            </div>
      
        </form>

    </div>
 

</div>
<div align="center">
    <button class="btn btn-primary btn-elevate kt-login__btn-primary"  style="margin-left:5px;border-radius: 50px;" cdkFocusInitial 
    (click)="sendEmail()">
        Done
    </button>
     <!-- [mat-dialog-close]="true" cdkFocusInitial -->
    <button class="btn btn-primary btn-elevate kt-login__btn-primary" mat-dialog-close style="margin-left:5px;border-radius: 50px;">
        Cancel
    </button>
</div> 
<div class="overlay" *ngIf="isLoading">
    <div class="center">
        <mat-spinner [diameter]="200"></mat-spinner>
    </div>
</div>