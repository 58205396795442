export class PageConfig {
	public defaults: any = {
		customerdashboard: {
			page: {
				title: 'Customer Dashboard',
				
			},
		},
		mealsummary: {
			page: {
				title: 'Meal Summary',
				
			},
		},
		balancesummary: {
			page: {
				title: 'Balance Summary',
				
			},
		},
		salesdashboard: {
			page: {
				title: 'Sales Dashboard',
				
			},
		},
		
		advancesearch: {
			page: {
				title: 'Check-In',
				desc: 'Search by QR Code or advance search'
			},
			
		},
		reservation: {
			page: {
				title: 'Booking',
				desc: 'Book your Seat'
			},
			
		},
		bookingsettings: {
			page: {
				title: 'Booking',
				desc: 'Book your Seat'
			},
			
		},
		'reservation-add': {
			page: {
				title: 'New Booking',
				desc: 'Book your Seat'
			},
			
		},

		giftcard: {
			page: {
				title: 'Gift Card',
				desc: 'Search by QR Code or advance search'
			},
			
		},

		loyaltyprofile: {
			page: {title: 'Loyalty Profile', desc: ''}
		},
		addCustomer: {
			page: {title: 'Add New Customer', desc: ''}
		},
		viewrecipe: {
			page: {title: 'Recipe', desc: ''}
		},
		campaign: {
			page: {title: 'Campaign', desc: ''}
		},
		orderhistory: {
			page: {title: 'Order History', desc: ''}
		},
		customer: {
			page: {title: 'Customer', desc: ''}
		},
		

		history: {
			page: {
				title: 'History',
				desc: 'Daily , Weekly and Mohtly history'
			},
		},
		// reservation: {
		// 	page: {
		// 		title: 'Reservation',
		// 		desc: 'Reservation'
		// 	},
		// },
		orders: {
			page: {
				title: 'Order',
				desc: 'Order'
			},
		},
		error: {
			404: {
				page: {title: '404 Not Found', desc: '', subheader: false}
			},
			403: {
				page: {title: '403 Access Forbidden', desc: '', subheader: false}
			}
		},
		wizard: {
			'wizard-1': {page: {title: 'Wizard 1', desc: ''}},
			'wizard-2': {page: {title: 'Wizard 2', desc: ''}},
			'wizard-3': {page: {title: 'Wizard 3', desc: ''}},
			'wizard-4': {page: {title: 'Wizard 4', desc: ''}},
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
