import { ShareDataService } from './../../../service/share-data.service';
import { Component, OnInit, ChangeDetectorRef,Inject, NgZone, OnChanges,Injectable  } from '@angular/core';
import { Router } from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
@Component({
  selector: 'kt-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
@Injectable()
export class NotificationComponent implements OnInit, OnChanges {

  payloadTitle: any;
  payloadBody: any;
  payloadData :any;
  audio :any;
  orderNumber : any;
  constructor(
    // @Injectable({})
    public shareData : ShareDataService,
    public ref : ChangeDetectorRef,
    public router :Router,
    private ngZone: NgZone,
    public dialogRef: MatDialogRef<NotificationComponent>
  ) {
      this.ngZone.run(() => {
        this.payloadTitle = this.shareData.notificationPayloadTitle
        this.payloadBody = this.shareData.notificationPayloadBody;
        this.audio = new Audio('../../../assets/short_message_tone.mp3');
        this.audio.play();
        // console.log("Shared payload")
        
        this.orderNumber = this.payloadTitle.toString();
        this.orderNumber = this.orderNumber.split(" ");
        this.orderNumber = this.orderNumber[1];
        this.shareData.getOrderDetailId = this.orderNumber
        
        // console.log("Shared COnsole")
      });
   }

  ngOnInit() {
     
    // console.log("Shared payload Data Only")
    // this.payloadData = this.payload.data
    // console.log(this.payload.data);
    // // src\assets\short_message_tone.mp3
   
    // this.ref.detectChanges();
    this.audio = new Audio('../../../assets/short_message_tone.mp3');
    this.audio.play();
    this.ref.detectChanges();
    
  }
  ngOnChanges(){
    this.ngZone.run(() => {
      console.log("After Called")
      this.ref.detectChanges();
    });
  }

  changeroute(url){
    this.ngZone.run(() => {
        this.ref.detectChanges();
        this.dialogRef.close();
        this.router.navigateByUrl('/'+url);
    });
    
  }
  onNoClick(): void {
    this.ngZone.run(() => {
      this.ref.detectChanges();
       this.dialogRef.close();
    });
  }

}

