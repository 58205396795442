import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ShareDataService } from './../../../service/share-data.service';
import { Dataservice } from '../../../core/auth';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { isThisISOWeek } from 'date-fns';

@Component({
  selector: 'kt-edit-recipe-option-form',
  templateUrl: './edit-recipe-option-form.component.html',
  styleUrls: ['./edit-recipe-option-form.component.scss']
})
export class EditRecipeOptionFormComponent implements OnInit {
  getRecipeId: any;
  getCategoryId: any;
  brandId = localStorage.getItem('BrandID');
  getExtraId: any;
  getExtraArray: any;
  extraRecipeData: any;
  isDataUploaded : boolean = false;

  recipeData: any;
  // Recipe Detail
  recipeTitle: any;
  recipeOriginalPrice: any;
  recipeImage: any;

  // 
  recipeDescription: any;
  recipeStatus: boolean;
  recipePostTime: any;
  recipeStock: boolean;
  categoryTitle: any;
  printerCode: any;
  isNewlyAdded: boolean;
  forDelivery: boolean;
  forCollection: boolean;
  forDineIn: boolean;
  isRecommended: boolean;
  isPopular: boolean;
  // Recipe Extras
  recipeExtras: any;
  showRecipeExtraButton: boolean = true;

  // Extra Detail
  getExtras: any;
  getExtrasOrg: any;

  // Get Detail
  getExtraName: any;
  getExtraPrice: any;
  getExtraImage: any;

  // Form
  editOptionForm: FormGroup;

  // Spinner
  isLoading: boolean = false;
  alertMessage: any;

  // Image
  url: any;
  getImage: boolean = false;
  // Submission
  imageUrl: any;
  fileToUpload: File = null;

  constructor(
    private sharedata: ShareDataService,
    private dataService: Dataservice,
    private ref: ChangeDetectorRef,
    private router: Router,
    public dialogRef: MatDialogRef<EditRecipeOptionFormComponent>
  ) { }

  ngOnInit() {
    this.isLoading = true; 
    this.getRecipeId = this.sharedata.RecipeId;
    this.getCategoryId = this.sharedata.categoryId;
    this.getExtraId = this.sharedata.extrasId;
    this.getExtraArray = this.sharedata.extraArray;
    console.log("this.getExtraArray")
    console.log(this.getExtraArray)
    this.getExtraArray.forEach(element => {
      if (this.getExtraId == element.extraId) {
        this.extraRecipeData = element;
        this.url = element.Image;
        if (this.url != "") {
          this.getImage = true;
        }
      }
    });
    // Form
    this.editOptionForm = new FormGroup({
      extraTitle: new FormControl(),
      extraPrice: new FormControl(),
    });

    
    this.setValues();
    this.isLoading = false;
    this.ref.detectChanges();  


  }

  setValues() {
    this.editOptionForm.controls['extraTitle'].setValue(this.extraRecipeData.Title);
    this.editOptionForm.controls['extraPrice'].setValue(this.extraRecipeData.Price);
  }
  onSubmit() {
    console.log("Submit Called");
    this.isLoading = true;
    if (this.fileToUpload != null) {
      console.log("fileToUpload");
      const formData: FormData = new FormData();
      formData.append('fileKey', this.fileToUpload, this.fileToUpload.name);
      this.dataService.postImage('/UploadImage', formData).subscribe(
        res => {
          this.imageUrl = "https://giftclubimagestorage.blob.core.windows.net/images/" + res;
          console.log(`this.imageUrl:  ${this.imageUrl}`)
          this.getExtraArray.forEach(element => {
            if (this.getExtraId == element.extraId) {
              element.Title = this.editOptionForm.get('extraTitle').value;
              element.Price = this.editOptionForm.get('extraPrice').value
              element.Image = this.imageUrl
            }
          });
          this.isDataUploaded = true;
          if (this.isDataUploaded) {
            this.dialogRef.close();
          }
          this.ref.detectChanges();
          
        }
      );
    }else{
      console.log("NOT  fileToUpload");
      this.getExtraArray.forEach(element => {
        if (this.getExtraId == element.extraId) {
          element.Title = this.editOptionForm.get('extraTitle').value;
          element.Price = this.editOptionForm.get('extraPrice').value
          // element.Image = this.imageUrl
        }
      });
      this.isDataUploaded = true;
      if (this.isDataUploaded) {
        this.dialogRef.close();
      }
      this.ref.detectChanges();
    }
    // if(this.isDataUploaded){
    //   this.getExtraArray.forEach(element => {
    //     console.log(element)
    //   });
    // }
    
    // this.ref.detectChanges();
    
  
    


  }
  changeroute(url) {
    this.router.navigateByUrl('/' + url);
  }
  closeDialogue() {
    this.ref.detectChanges();
    if(this.isDataUploaded){
      this.dialogRef.close();
    }
    
  }
  public addFile(event: any, files) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.url = event.target.result;
        this.ref.detectChanges();
      }
      reader.readAsDataURL(event.target.files[0]);
      this.fileToUpload = files.item(0);
      console.log("FILE:: ");
      console.log(this.fileToUpload);
      this.ref.detectChanges();
      // this.ref.detectChanges();
      this.getImage = true;
    }

  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46 ) {
      return false;
    }
    return true;
  }

}
