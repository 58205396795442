// Angular
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
// RxJS
import { Observable, Subscription } from 'rxjs';
// Object-Path
import * as objectPath from 'object-path';
// Layout
import { LayoutConfigService, MenuConfigService, PageConfigService } from '../../../core/_base/layout';
import { HtmlClassService } from '../html-class.service';
import { LayoutConfig } from '../../../core/_config/layout.config';
// import { MenuConfig } from '../../../core/_config/menu.config';
import { MenuConfig } from './../../../core/_config/template.config';

import { PageConfig } from '../../../core/_config/page.config';
// User permissions
// import { NgxPermissionsService } from 'ngx-permissions';
import { currentUserPermissions, Dataservice, Permission } from '../../../core/auth';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../core/reducers';
import { LocationStrategy } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';


@Component({
	selector: 'kt-base',
	templateUrl: './base.component.html',
	styleUrls: ['./base.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class BaseComponent implements OnInit, OnDestroy {
	// Public variables
	selfLayout: string;
	asideDisplay: boolean;
	asideSecondary: boolean;
	subheaderDisplay: boolean;
	desktopHeaderDisplay: boolean;
	fitTop: boolean;
	fluid: boolean;
	menuTiles: any;

	// Dynamic Menu
	brandFlavor = localStorage.getItem('BrandFlavor');
	BrandUsername = localStorage.getItem('userName');
	menuItems: any;
	historyItems: any;
	dashboardItem: any;
	menuHoverItem: any
	allMenuItems: any;
	checkMenuItems: any;
	isGet: boolean = false;
	isAllLoaded: boolean = false;
	merchantData: any;
	additionalattributes: any;

	// Dynamic Menu Ends
	orderSettingTemplate: any;




	// Private properties
	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
	private currentUserPermissions$: Observable<Permission[]>;


	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 * @param menuConfigService: MenuConfifService
	 * @param pageConfigService: PageConfigService
	 * @param htmlClassService: HtmlClassService
	 * @param store
	 * @param permissionsService
	 */
	constructor(
		private layoutConfigService: LayoutConfigService,
		private menuConfigService: MenuConfigService,
		private pageConfigService: PageConfigService,
		private dataservice: Dataservice,
		private ref: ChangeDetectorRef,
		private htmlClassService: HtmlClassService,
		private store: Store<AppState>,
		// private permissionsService: NgxPermissionsService,
		private router: Router) {


		this.loadRolesWithPermissions();


		// register configs by demos

		this.layoutConfigService.loadConfigs(new LayoutConfig().configs);
		// this.menuConfigService.loadConfigs(new MenuConfig().configs);

		//  this.menuConfigService.loadConfigs(new MenuConfig(dataservice).configs); 
		this.pageConfigService.loadConfigs(new PageConfig().configs);

		// setup element classes
		this.htmlClassService.setConfig(this.layoutConfigService.getConfig());

		const subscr = this.layoutConfigService.onConfigUpdated$.subscribe(layoutConfig => {
			// reset body class based on global and page level layout config, refer to html-class.service.ts
			document.body.className = '';
			this.htmlClassService.setConfig(layoutConfig);
		});
		this.unsubscribe.push(subscr);
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.getMenu();



	}

	getTemplateData(username) {
		// https://perkssrestapidevelopment.azurewebsites.net/api/Merchant/get/tcg.admin
		// console.log(`username : ${username}`)
		this.dataservice.get("Merchant/get/" + username, []).subscribe(
			res => {
				if (res["Info"]["Status"] == 200) {
					this.merchantData = res["Data"]["TemplateData"].AdditionalAttributes;
					this.orderSettingTemplate = res["Data"]["TemplateData"].OrderSettingTemplate;
					// console.log(`this.orderSettingTemplate : ${this.orderSettingTemplate}`)
					localStorage.setItem("orderSettingTemplate", this.orderSettingTemplate)
					//   console.log(this.merchantData);
				} else {
					// alert(res["Info"]["Message"];
				}
			}
		)
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.forEach(sb => sb.unsubscribe());
	}



	/**
	 * NGX Permissions, init roles
	 */
	loadRolesWithPermissions() {

		this.currentUserPermissions$ = this.store.pipe(select(currentUserPermissions));
		const subscr = this.currentUserPermissions$.subscribe(res => {
			if (!res || res.length === 0) {
				return;
			}

			// this.permissionsService.flushPermissions();
			// res.forEach((pm: Permission) => this.permissionsService.addPermission(pm.name));
		});
		this.unsubscribe.push(subscr);
	}

	// Dynamic Menu
	getMenu() {
		//  this.dataservice.get("Config/getBrandTemplateConfig/"  + this.brandFlavor + "/mobilebusiness", [])
		this.dataservice.get("Merchant/get/" + this.BrandUsername, []).subscribe(
			res => {
				if (res["Info"]["Status"] == 200) {
					// this.getTemplateData(this.BrandUsername);
					// console.log(this.merchantData);
					this.menuHoverItem = res["Data"]["TemplateData"]["HomeTemplate"].Tiles;
					// this.menuHoverItem.push({
					// 	DisplayOrder: 9,
					// 	Image: "https://giftclubimagestorage.blob.core.windows.net/merchant/app_landing_icons/dashboard.png",
					// 	Redirection: "pricegroup",
					// 	Title: "Price Group",
					// 	Token: "pricegroup",
					// 	WebLink: null,
					// 	index: 8,
					// 	root: true,
					// 	submenu: [
					// 		{
					// 			DisplayOrder: 1,
					// 			Image: "https://giftclubimagestorage.blob.core.windows.net/images/customer_dashboard.png",
					// 			Redirection: "product",
					// 			Title: "Product",
					// 			Token: "product",
					// 			WebLink: null,
					// 			page: "/hks",
					// 		},
					// 		{
					// 			DisplayOrder: 1,
					// 			Image: "https://giftclubimagestorage.blob.core.windows.net/images/customer_dashboard.png",
					// 			Redirection: "Distributors",
					// 			Title: "Distributors",
					// 			Token: "Distributors",
					// 			WebLink: null,
					// 			page: "/hks/distributor",
					// 		},
					// 		{
					// 			DisplayOrder: 1,
					// 			Image: "https://giftclubimagestorage.blob.core.windows.net/images/customer_dashboard.png",
					// 			Redirection: "Gallery",
					// 			Title: "Gallery",
					// 			Token: "Gallery",
					// 			WebLink: null,
					// 			page: "/hks/gallery",
					// 		}
					// 	],
					// 	toggle: "hover",
					// })

					// console.log(this.menuHoverItem)
					this.historyItems = res["Data"]["TemplateData"]["HistoryTemplate"].Tiles;
					this.additionalattributes = res["Data"]["TemplateData"]["AdditionalAttributes"];
					this.historyItems.forEach(obj => {
						if (obj.Token == 'Loyalty' || obj.Token == 'loyalty') {
							obj.page = '/history'
						}
						if (obj.Token == 'Booking' || obj.Token == 'booking') {
							obj.page = '/booking-history'
						}
						if (obj.Token == 'Order' || obj.Token == 'order') {
							obj.page = '/orderhistory'
						}
						if (obj.Token == 'product-parts' || obj.Token == 'Product-parts') {
							obj.page = '/hks'
						}
						if (obj.Token == 'galary' || obj.Token == 'gallery') {
							obj.page = '/hks/gallery'
						}
						if (obj.Token == 'Distributors' || obj.Token == 'distributors') {
							obj.page = '/hks/distributor'
						}
						if (obj.Token == 'pricegroup' || obj.Token == 'pricegroup') {
							obj.page = '/hks/pricegroup'
						}
						
					});
					this.dashboardItem = res["Data"]["TemplateData"]["DashboardTemplate"].Tiles;
					// console.log("Additional")
					// console.log(this.additionalattributes);
					try {
						if (this.additionalattributes.ShowOrderReportInDashboard) {
							// console.log(`len ${this.dashboardItem.length}`)
							this.dashboardItem.push(
								{
									Redirection: "orderreport",
									Title: "Order Report",
									Token: "orderreport",
								}
							)
						}
					}
					catch (ex) {
						console.log(ex);
					}

					// if(this.merchantData.ShowOrderReportInDashboard){
					// 	console.log(`len ${this.dashboardItem.length}`)

					// }
					this.dashboardItem.forEach(obj => {
						// console.log(obj);
						if (obj.Token == 'Customer' || obj.Token == 'customer') {
							// obj.title = "Customer Dashboard"
							obj.page = '/customerdashboard'
						}
						else if (obj.Token == 'payment' || obj.Token == 'Payment') {
							obj.page = '/payment-dashboard'
						}
						else if (obj.Token == 'Sale' || obj.Token == 'sale') {
							obj.page = '/salesdashboard'
						}
						else if (obj.Token == 'mealsummary' || obj.Token == 'Mealsummary') {
							obj.page = '/mealsummary'
						}
						else if (obj.Token == 'orderreport' || obj.Token == 'orderreport') {
							obj.page = '/orderreport'
						}
						else if (obj.Token == 'balancesummary' || obj.Token == 'Balancesummary') {
							obj.page = '/balancesummary'
						}
					});
					// console.log(`this.historyItems: ${this.historyItems}`)


					this.menuHoverItem.forEach(obj => {
						if (obj.Token == 'history') {
							obj.toggle = 'hover'
							obj.root = true
							obj.submenu = this.historyItems
						}
						if (obj.Token == 'dashboard') {
							obj.toggle = 'hover'
							obj.root = true
							obj.submenu = this.dashboardItem
						}
						if (obj.Token == 'order') {
							obj.title = 'Orders'
							obj.page = '/orders';
						}
						if (obj.Token == 'giftcard') {
							obj.page = '/giftcard'
						}
						if (obj.Token == 'scan') {
							obj.page = '/advancesearch'
						}
						if (obj.Token == 'customer') {
							obj.page = '/customer'
						}
						if (obj.Token == 'recipe') {
							obj.page = '/viewrecipe'
						}
						if (obj.Token == 'campaign') {
							obj.page = '/campaign'
						}
						if (obj.Token == 'booking') {
							obj.page = '/reservation'
						}
						if (obj.Token == 'product') {
							obj.page = '/viewproduct'
						}
						if (obj.Token == 'product-parts' || obj.Token == 'Product-parts') {
							obj.page = '/hks'
						}
						if (obj.Token == 'galary' || obj.Token == 'gallery') {
							obj.page = '/hks/gallery'
						}
						if (obj.Token == 'Distributors' || obj.Token == 'distributors') {
							obj.page = '/hks/distributor'
						}
						if (obj.Token == 'pricegroup' || obj.Token == 'pricegroup') {
							obj.page = '/hks/pricegroup'
						}

					});

					this.menuHoverItem.forEach((obj, index) => {
						obj.index = index
					});
					this.menuHoverItem.forEach((obj, index) => {
						if (obj.Token == 'phoneorder') {
							this.menuHoverItem.splice(index, 1);
						}
					});


					this.menuItems = res["Data"]["TemplateData"]["HomeTemplate"].Tiles;
					// console.log("MenuItem")
					this.menuItems.forEach(obj => {
						if (obj.Token == 'history') {
							obj.root = true
							obj.submenu = this.historyItems
						}
						if (obj.Token == 'dashboard') {
							obj.root = true
							obj.submenu = this.dashboardItem
						}
						if (obj.Token == 'order') {
							obj.page = '/orders'
						}
						if (obj.Token == 'giftcard') {
							obj.page = '/giftcard'
						}
						if (obj.Token == 'scan') {
							obj.page = '/advancesearch'
						}
						if (obj.Token == 'customer') {
							obj.page = '/customer'
						}
						if (obj.Token == 'recipe') {
							obj.page = '/viewrecipe'
						}
						if (obj.Token == 'campaign') {
							obj.page = '/campaign'
						}
						if (obj.Token == 'booking') {
							obj.page = '/reservation'
						}
						if (obj.Token == 'product') {
							obj.page = '/viewproduct'
						}
					});

					// this.menuItems.forEach(obj => {
					// 	console.log(obj)
					// });

					this.allMenuItems = {
						header: {
							self: {},
							items: this.menuHoverItem
						},
						aside: {
							self: {},
							items: this.menuItems
						}
					}

					// this.loadConfigs();
					this.menuConfigService.loadConfigs(this.allMenuItems);
					this.ref.detectChanges();
					this.isAllLoaded = true;


					const config = this.layoutConfigService.getConfig();
					this.selfLayout = objectPath.get(config, 'self.layout');
					this.asideDisplay = objectPath.get(config, 'aside.self.display');
					this.subheaderDisplay = objectPath.get(config, 'subheader.display');
					this.desktopHeaderDisplay = objectPath.get(config, 'header.self.fixed.desktop');
					this.fitTop = objectPath.get(config, 'content.fit-top');
					this.fluid = objectPath.get(config, 'content.width') === 'fluid';

					// let the layout type change
					const subscr = this.layoutConfigService.onConfigUpdated$.subscribe(cfg => {
						setTimeout(() => {
							this.selfLayout = objectPath.get(cfg, 'self.layout');
						});
					});
					this.unsubscribe.push(subscr);
					this.ref.detectChanges();
				}



			}
		);
		if (this.dataservice.isNewlyLogin) {
			// console.log(`this.dataservice.isNewlyLogin : ${this.dataservice.isNewlyLogin}`)
			// this.dataservice.isNewlyLogin = false;
			this.menuConfigService.getMenus()
		}

	}
	// 

	changeroute(url) {
		this.router.navigateByUrl('/' + url);
	}


}
