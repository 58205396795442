import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ShareDataService } from './../../../service/share-data.service';
import { Dataservice } from '../../../core/auth';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'kt-add-points',
  templateUrl: './add-points.component.html',
  styleUrls: ['./add-points.component.scss']
})
export class AddPointsComponent implements OnInit {
  
  brandId = localStorage.getItem('BrandID');
  getPoints :any;
  addAmount :any;
  customerInfo :any;
  unitPoints :any;
  // Spinner
  isLoading : boolean = false;
  alertMessage: any;
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dataService: Dataservice,
    private shareData : ShareDataService,
  ) { }

  ngOnInit() {
    this.customerInfo = this.shareData.pointCustomerInfo;
    this.getPoints = this.data.points
    this.customerInfo.LoyaltyAccountConfig.forEach(obj => {
      if(obj.PointsBy == "Unit"){
        this.unitPoints = obj.Id;
      }
    });
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46 ) {
      return false;
    }
    return true;

  }
  submitAddPoint(){
    this.isLoading = true;
    let params;
    params = {
      Id: this.customerInfo.Id,
      Identity : this.customerInfo.Identity,
      Points : this.addAmount,
      FK_LoyaltyAccountId : this.customerInfo.FK_LoyaltyAccountId,
      QrImageUrl : this.customerInfo.QrImageUrl
    }
    // const checkedData = this.dataService.put("LoyaltyAccountHolder/updatePoints/"+this.unitPoints+"/"+this.addAmount,params)
    // .pipe(
    //   catchError(this.handleError)
    // );
   
    // https://perkssrestapidevelopment.azurewebsites.net/api/LoyaltyAccountHolder/updatePoints/516/2
    this.dataService.put("LoyaltyAccountHolder/updatePoints/"+this.unitPoints+"/"+this.addAmount,params).subscribe(
      res=>{
        // console.log(res);
        
        this.alertMessage = "Updated successfully"
        this.isLoading = false;
        alert(this.alertMessage)
      },(error) => {
            this.alertMessage = error.error["Message"]
            // console.log(error.error);
            this.isLoading = false;
            alert(this.alertMessage)
      }
    )
  }
  // handleError(error: HttpErrorResponse) {
  //   console.log("ERROR")
  //   return throwError(error);
  // }

}
export interface DialogData {
  points: any;
}