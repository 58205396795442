import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ShareDataService } from './../../../service/share-data.service';
import { Dataservice } from '../../../core/auth';

@Component({
  selector: 'kt-order-email',
  templateUrl: './order-email.component.html',
  styleUrls: ['./order-email.component.scss']
})
export class OrderEmailComponent implements OnInit {
  brandId = localStorage.getItem('BrandID');
  // 
  orderid :any;
  customerEmail: any;
  // Spinner
  isLoading : boolean = false;
  alertMessage: any;
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dataService: Dataservice,
    private ref: ChangeDetectorRef,
    private shareData : ShareDataService,
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.orderid = this.data.orderid;
    // https://perkssrestapidevelopment.azurewebsites.net/api/Order/getCustomerOrderDetailByOrderId_v1/22736
    this.dataService.get("Order/getCustomerOrderDetailByOrderId_v1/"+this.data.orderid,[]).subscribe(
      res=>{
        // console.log(res);
        this.customerEmail= res["OrderAndPaymentInfo"]["Customer"].CustomerEmail;
        this.isLoading = false;
        this.ref.detectChanges();
      }
    );
  }
  sendEmail(){
    this.isLoading = true;
    // https://perkssrestapidevelopment.azurewebsites.net/api/Order/buttonEmail/23574/danialsohail145@gmail.com
    console.log(this.customerEmail);
    this.dataService.post("Order/buttonEmail/" + this.orderid +"/"+this.customerEmail,[]).subscribe(
      res=>{
        console.log(res); 
        if(res["Info"]["Status"] == 200){
          alert(res["Info"]["Message"])
          this.isLoading = false;
          this.ref.detectChanges();
        }else{
          alert(res["Info"]["Message"])
          this.isLoading = false;
          this.ref.detectChanges();
        }
       
      }
    );
  }

}
export interface DialogData {
  orderid: any;
}