<div class="kt-widget26">
	<div class="kt-widget26__content">
		<span class="kt-widget26__desc">{{asc}}</span>
		<span class="kt-widget26__number">
			<i style="color: #f2a800;" *ngIf="ico == 'single'" class="fas fa-user"></i>
			<i style="color: green;" *ngIf="ico == 'frequent'" class="fas fa-user-friends"></i>
			<i style="color: green;" *ngIf="ico == 'loyal'" class="fas fa-users"></i>
			<i style="color: red;" *ngIf="ico == 'infrequent'" class="fas fa-user"></i>
			{{value}}</span>
		<span class="kt-widget26__desc">{{desc}}</span>
		
	</div>
	<!-- <div class="kt-widget26__chart" style="height:100px; width: 230px;">
		<canvas ktSparklineChart [options]="options" id="kt_chart_quick_stats_1"></canvas>
	</div> -->
</div>
