import { Component, OnInit, ChangeDetectorRef} from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Dataservice } from '../../../core/auth';
import { ShareDataService } from './../../../service/share-data.service';

@Component({
  selector: 'kt-edit-timing-setting',
  templateUrl: './edit-timing-setting.component.html',
  styleUrls: ['./edit-timing-setting.component.scss']
})
export class EditTimingSettingComponent implements OnInit {

  brandFlavor = localStorage.getItem('BrandFlavor');
  editTimeSlot : FormGroup;
  startHour :any;
  startMin : any;
  startAm :any;
  endHour :any;
  endMin : any;
  endAm :any;
  formatedTime : any;
  hoursArray = ["01","02" ,"03","04","05","06","07","08","09","10","11","12"];
  minArray = [
    "00","01","02","03","04","05","06","07","08","09","10",
    "11","12","13","14","15","16","17","18","19","20","21",
    "22","23","24","25","26","27","28","29","30","31","32",
    "33","34","35","36","37","38","39","40","41","42","43",
    "44","45","46","47","48","49","50","51","52","53","54",
    "55","56","57","58","59","60"
  ]
  amArray = ["AM","PM"]
  // Spinner
  isLoading : boolean = false;
  alertMessage: any;

  // 
  dateSlots :any;
  slotType :any;
  otherSlot : any;

  // Edit
  editSlot : any;
  editStartSlot : any;
  editEndSlot : any;


  constructor(
    private dataService: Dataservice,
    private ref: ChangeDetectorRef,
    private router : Router,
    private sharedata : ShareDataService,
  ) { }

  ngOnInit() {
    this.editSlot = this.sharedata.editTimingSlot;
    this.editSlot = this.editSlot.split("-");

    this.editStartSlot = this.editSlot[0];
    this.editStartSlot = this.editStartSlot.split(" ");
    this.startAm = this.editStartSlot[1];
   
    this.editStartSlot = this.editStartSlot[0]
    this.editStartSlot = this.editStartSlot.split(":");
    this.startHour = this.editStartSlot[0];
    this.startMin = this.editStartSlot[1];
    // console.log(`this.startHour : ${this.startHour}`)
    // console.log(`this.startMin : ${this.startMin}`)
    // console.log(`this.startAm : ${this.startAm}`)
    
    


    this.editEndSlot = this.editSlot[1];
    this.editEndSlot = this.editEndSlot.split(" ");
    this.endAm = this.editEndSlot[2];

    this.editEndSlot = this.editEndSlot[1]
    this.editEndSlot = this.editEndSlot.split(":");
    this.endHour = this.editEndSlot[0];
    this.endMin = this.editEndSlot[1];

    // console.log(`this.endHour : ${this.endHour}`)
    // console.log(`this.endMin : ${this.endMin}`)
    // console.log(`this.endAm : ${this.endAm}`)

    this.slotType = this.sharedata.timingType;
    this.dataService.get("Order/getTimeSlots/"+this.brandFlavor,[] ).subscribe(
      res=>{
        if(this.slotType == "DeliveryTimeSlots"){
          this.dateSlots = res["DeliveryTimeSlots"];
          this.otherSlot = res["PickupTimeSlots"];
        }
        else{
          this.dateSlots = res["PickupTimeSlots"];
          this.otherSlot = res["DeliveryTimeSlots"]; 
        }
        this.dateSlots.forEach(obj => {
          if(obj._Day ==  this.sharedata.timingDay){
            obj._Slots.splice(this.sharedata.editTimingIndex, 1);
          }
        });
      }
    );
  }

  setValues(){
    this.isLoading = true;
    this.formatedTime = this.startHour + ":" + this.startMin +" "+ this.startAm + " - " + this.endHour + ":" + this.endMin+ " " + this.endAm
    

    this.dateSlots.forEach(obj => {
      if(obj._Day ==  this.sharedata.timingDay){
        obj._Slots.push(this.formatedTime);
      }
    });
   
    let params;
    if(this.slotType == "DeliveryTimeSlots"){
      params = {
        DeliveryTimeSlots : this.dateSlots,
        PickupTimeSlots : this.otherSlot
      }
    }
    else{
      params = {
        DeliveryTimeSlots : this.otherSlot,
        PickupTimeSlots : this.dateSlots
      }
    }
    this.dataService.put("Order/updateTimeSlots/"+this.brandFlavor,params ).subscribe(
      res=>{
        this.alertMessage = res["Info"]["Message"];
        this.isLoading = false;
        alert(this.alertMessage);
        this.ref.detectChanges();
        
      }
    );
    this.reloadCurrentRoute();

  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }

}
