<h1 mat-dialog-title style="text-align: center;padding-top: 3%;">{{payloadTitle}}</h1>
<div mat-dialog-content>
    <p style="text-align: center;">
        {{payloadBody}}
    </p>
</div>
<div align="center">
    <button class="btn btn-primary btn-elevate kt-login__btn-primary"  style="margin-left:5px;border-radius: 50px;"  cdkFocusInitial (click)="changeroute('orderdetail')">
       Go To orders
    </button>
     <!-- [mat-dialog-close]="true" cdkFocusInitial -->
     <!-- <button mat-button (click)="onNoClick()" >Close</button> -->
    <button class="btn btn-primary btn-elevate kt-login__btn-primary" mat-dialog-close style="margin-left:5px;border-radius: 50px;" (click)="onNoClick()">
        Cancel
    </button>
</div>