import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { DatasharingService } from "../loyaltyprofile/datasharing.service";

@Component({
	selector: "kt-pop-up-modalcomponent",
	templateUrl: "./pop-up-modalcomponent.component.html",
	styleUrls: ["./pop-up-modalcomponent.component.scss"],
})
export class PopUpModalcomponentComponent implements OnInit {
	// @Input() modal_title;
	// @Input() modal_content;
	// @Input() Button_Text;

	modal_content: string;

	constructor(
		private ref: ChangeDetectorRef,
		public dialogRef: MatDialogRef<PopUpModalcomponentComponent>,
		private dataSharingService: DatasharingService
	) {}

	ngOnInit() {
		this.modal_content = this.dataSharingService.AlertMessage;
	}

	onNoClick(): void {
		this.ref.detectChanges();
		this.dialogRef.close();
	}
}

export class ConfirmDialogModel {
	constructor(public title: string, public message: string) {}
}
