import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Angular
import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { OverlayModule } from "@angular/cdk/overlay";
// Angular in memory
import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";
// Perfect Scroll bar
import {
	PERFECT_SCROLLBAR_CONFIG,
	PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";
// SVG inline
// import { InlineSVGModule } from 'ng-inline-svg';
// Env
import { environment } from "../environments/environment";
// NGX Permissions
// import { NgxPermissionsModule } from 'ngx-permissions';
// NGRX
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
// State
import { metaReducers, reducers } from "./core/reducers";
// Copmponents
import { AppComponent } from "./app.component";
// Modules
import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";
import { ThemeModule } from "./views/theme/theme.module";
// Partials
import { PartialsModule } from "./views/partials/partials.module";
// Layout Services
import {
	DataTableService,
	FakeApiService,
	KtDialogService,
	LayoutConfigService,
	LayoutRefService,
	MenuAsideService,
	MenuConfigService,
	MenuHorizontalService,
	PageConfigService,
	SplashScreenService,
	SubheaderService,
} from "./core/_base/layout";
// Auth
import { AuthModule } from "./views/pages/auth/auth.module";
import { AuthService, Dataservice } from "./core/auth";
// CRUD
import {
	HttpUtilsService,
	LayoutUtilsService,
	TypesUtilsService,
} from "./core/_base/crud";
// Config
import { LayoutConfig } from "./core/_config/layout.config";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
// import { AngularFireMessagingModule } from '@angular/fire/messaging';
// import { AngularFireDatabaseModule } from '@angular/fire/database';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AngularFireModule } from '@angular/fire';
// import { MessagingService } from './service/messaging.service';
import { AsyncPipe, DatePipe } from "../../node_modules/@angular/common";
import { MatDialogModule } from "@angular/material/dialog";
import { EditRecipeOptionFormComponent } from "./views/pages/edit-recipe-option-form/edit-recipe-option-form.component";
import { AddRadiusComponent } from "./views/pages/add-radius/add-radius.component";
import { AddPostcodeComponent } from "./views/pages/add-postcode/add-postcode.component";
import { EditPostcodeComponent } from "./views/pages/edit-postcode/edit-postcode.component";
import { EditRadiusComponent } from "./views/pages/edit-radius/edit-radius.component";
import { NotificationComponent } from "./views/pages/notification/notification.component";
import { AddTimingSlotComponent } from "./views/pages/add-timing-slot/add-timing-slot.component";
//
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { EditTimingSettingComponent } from "./views/pages/edit-timing-setting/edit-timing-setting.component";
import { AddPointsComponent } from "./views/pages/add-points/add-points.component";
import { AddBalanceComponent } from "./views/pages/add-balance/add-balance.component";
import { DeductBalanceComponent } from "./views/pages/deduct-balance/deduct-balance.component";
import { OrderEmailComponent } from "./views/pages/order-email/order-email.component";
import { AddRecipeOptionFormComponent } from "./views/pages/add-recipe-option-form/add-recipe-option-form.component";
import { AddGiftCardComponent } from "./views/pages/add-gift-card/add-gift-card.component";

// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	wheelSpeed: 0.5,
	swipeEasing: true,
	minScrollbarLength: 40,
	maxScrollbarLength: 300,
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
	// initialize app by loading default demo layout config
	return () => {
		if (appConfig.getConfig() === null) {
			appConfig.loadConfigs(new LayoutConfig().configs);
		}
	};
}

@NgModule({
	declarations: [
		AppComponent,
		EditRecipeOptionFormComponent,
		AddRadiusComponent,
		AddPostcodeComponent,
		EditPostcodeComponent,
		EditRadiusComponent,
		NotificationComponent,
		AddTimingSlotComponent,
		EditTimingSettingComponent,
		AddPointsComponent,
		AddBalanceComponent,
		DeductBalanceComponent,
		OrderEmailComponent,
		AddRecipeOptionFormComponent,
		AddGiftCardComponent,
	],
	imports: [
		HttpClientModule,
		BrowserAnimationsModule,
		BrowserModule,
		AppRoutingModule,
		environment.isMockEnabled
			? HttpClientInMemoryWebApiModule.forRoot(FakeApiService, {
					passThruUnknownUrl: true,
					dataEncapsulation: false,
			  })
			: [],
		PartialsModule,
		CoreModule,
		OverlayModule,
		AuthModule.forRoot(),
		TranslateModule.forRoot(),
		MatProgressSpinnerModule,
		FormsModule,
		ReactiveFormsModule,
		MatDialogModule,
		MatSelectModule,
		MatFormFieldModule,
		MatInputModule,
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory,
		}),
		EffectsModule.forRoot([]),
		StoreRouterConnectingModule.forRoot({ stateKey: "router" }),
		StoreDevtoolsModule.instrument(),
		StoreModule.forRoot(reducers, { metaReducers }),
	],
	exports: [],
	providers: [
		AuthService,
		Dataservice,
		LayoutConfigService,
		LayoutRefService,
		MenuConfigService,
		PageConfigService,
		KtDialogService,
		DataTableService,
		SplashScreenService,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
		},
		{
			// layout config initializer
			provide: APP_INITIALIZER,
			useFactory: initializeLayoutConfig,
			deps: [LayoutConfigService],
			multi: true,
		},
		SubheaderService,
		MenuHorizontalService,
		MenuAsideService,
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService,
		AsyncPipe,
		DatePipe,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
