import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShareDataService { 

  RecipeId : any;
  categoryId :any;
  campaignId : any;
  promotionId :any;
  discountDealId:any;

  // 
  extrasId : any;
  extraOptionId : any;
  extraArray : any;
  isExtraChanges : boolean;
  isNewlyAdded : boolean;

  // Radius (Order Setting)
  postcodeId : any;
  radiusId : any;
  //
  campaignDealId : any;
  campaignType : any;

  // Notification
  notificationPayload :any
  notificationPayloadTitle :any;
  notificationPayloadBody :any;

  //Timing 
  timingDay :any;
  timingType: any;

  // Eidt Timing
  editTimingIndex :any;
  editTimingSlot : any;
  // Customer Detail
  updateCustomer : any;
  customerId :number;
  backFromDetail :any;
  // Points
  pointCustomer : any;
  // Balance
  customerBalance :any;
  pointCustomerInfo : any;
  // OrderDetail
  getOrderDetailId : any;
  orderDetailBackRoute : string;
  // GiftCard
  giftCardId : any;
  giftCardAccountConfigId : number;
  // Booking Slot
  selectedSlotFor : any;
  // Product
  productId : number;
  productCategory : any;
  productSubCategory : any;

  // 

  constructor() { }
}
