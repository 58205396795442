import { Component, OnInit, ChangeDetectorRef} from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Dataservice } from '../../../core/auth';
import { ShareDataService } from './../../../service/share-data.service';

@Component({
  selector: 'kt-add-timing-slot',
  templateUrl: './add-timing-slot.component.html',
  styleUrls: ['./add-timing-slot.component.scss']
})
export class AddTimingSlotComponent implements OnInit {
  brandFlavor = localStorage.getItem('BrandFlavor');
  addTimeSlot : FormGroup;
  startHour :any;
  startMin : any;
  startAm :any;
  endHour :any;
  endMin : any;
  endAm :any;
  formatedTime : any;
  hoursArray = ["01","02" ,"03","04","05","06","07","08","09","10","11","12"];
  minArray = [
    "00","01","02","03","04","05","06","07","08","09","10",
    "11","12","13","14","15","16","17","18","19","20","21",
    "22","23","24","25","26","27","28","29","30","31","32",
    "33","34","35","36","37","38","39","40","41","42","43",
    "44","45","46","47","48","49","50","51","52","53","54",
    "55","56","57","58","59","60"
  ]
  amArray = ["AM","PM"]
  // Spinner
  isLoading : boolean = false;
  alertMessage: any;

  // 
  dateSlots :any;
  slotType :any;
  otherSlot : any;

  constructor(
    private dataService: Dataservice,
    private ref: ChangeDetectorRef,
    private router : Router,
    private sharedata : ShareDataService,
  ) { }

  ngOnInit() {
    this.addTimeSlot = new FormGroup({
      startHour : new FormControl(),
      endTime : new FormControl(),
    });
    this.startHour = this.hoursArray[0]
    this.startMin = this.minArray[0]
    this.startAm = this.amArray[0]
    this.endHour = this.hoursArray[0]
    this.endMin = this.minArray[0]
    this.endAm = this.amArray[0];
    this.slotType = this.sharedata.timingType;
    this.dataService.get("Order/getTimeSlots/"+this.brandFlavor,[] ).subscribe(
      res=>{
        if(this.slotType == "DeliveryTimeSlots"){
          this.dateSlots = res["DeliveryTimeSlots"];
          this.otherSlot = res["PickupTimeSlots"];
        }
        else{
          this.dateSlots = res["PickupTimeSlots"];
          this.otherSlot = res["DeliveryTimeSlots"]; 
        }
        
      }
    );
  }

  setValues(){
    this.isLoading = true;
    this.formatedTime = this.startHour + ":" + this.startMin +" "+ this.startAm + " - " + this.endHour + ":" + this.endMin+ " " + this.endAm
    
    this.dateSlots.forEach(obj => {
      if(obj._Day ==  this.sharedata.timingDay){
        obj._Slots.push(this.formatedTime);
      }
    });
   
    let params;
    if(this.slotType == "DeliveryTimeSlots"){
      params = {
        DeliveryTimeSlots : this.dateSlots,
        PickupTimeSlots : this.otherSlot
      }
    }
    else{
      params = {
        DeliveryTimeSlots : this.otherSlot,
        PickupTimeSlots : this.dateSlots
      }
    }
    this.dataService.put("Order/updateTimeSlots/"+this.brandFlavor,params ).subscribe(
      res=>{
        this.alertMessage = res["Info"]["Message"];
        this.isLoading = false;
        alert(this.alertMessage);
        this.ref.detectChanges();
        
      }
    );
    // this.reloadCurrentRoute();

  }
  

}
