import { CarsTable } from './../../../../../core/e-commerce/_server/cars.table';

// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dataservice } from '../../../../../core/auth/';

@Component({
  selector: 'kt-decline-dialog',
  templateUrl: './decline-dialog.component.html'
})
export class DeclineDialogComponent implements OnInit {
	// Public properties
	viewLoading = false;
  	carTable: CarsTable;

	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<DeclineDialogComponent>
	 * @param data: any
	 */
	constructor(
		public dialogRef: MatDialogRef<DeclineDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any, private dataService: Dataservice 
	) { }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.carTable = new CarsTable(this.dataService);
	}

	/**
	 * Close dialog with false result
	 */
	onNoClick(): void {
		this.dialogRef.close();
	}

	/**
	 * Close dialog with true result
	 */
	onYesClick(): void {
    /* Server loading imitation. Remove this */
    this.carTable.updateReservation(this.data.status , this.data.id);
    window.location.reload();
		setTimeout(() => {
			this.dialogRef.close(true); // Keep only this row
		}, 2500);
	}
}
