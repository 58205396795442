import { Injectable } from '@angular/core';
// import { CustomersPageRequested } from 'src/app/core/e-commerce';

@Injectable({
  providedIn: 'root'
})
export class DatasharingService {

  customerID: number;
  cardCustomerID: number;

  CustomerEmail: string;
  CustomerFirstName: string;
  CustomerLastName: string;
  CustomerMobile: string;
  CustomerImageUrl : string;
  QRCode : string;
  
  CustomerSettingId : Number;
	IsMarketingEmail: Boolean;
	IsMarketingSms: Boolean;
  IsMarketingPushNotification: Boolean;
  
  AlertMessage: string;

  RewardTitle: string;
  RewardImage: string;
  RewardType: string;
  RewardID: number;


}
