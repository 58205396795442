// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
	// intercept request and add token
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// tslint:disable-next-line:no-debugger
		// modify request
		request = request.clone({
			// setHeaders: {
			// 	//Authorization: `Bearer 6gLROWFHfvvZX4sNGvSIYbYYMFYPueXMAS-grcgcPJTZunwZVq_OLYwqoRONvTujSj7DvRRZuIygvpJidFogR0qIc6nFb-llAgV6cs4XosACXpj1mQXYFgYDq9poiH1AuGR8wYm0oYGVXEJHMoF_qbUOG78GI7WahdQIl9qzr_BpEbpdH-M7VW1A9WLjPRrgc7Xzk6keGmhze4CNDxRHmPUjaJ67c7p0BziaKVQWcY60TWSp9UNtXSucvEy3s830ACA3J0pXJTASyl4Df0GCrIG1E8pIGMMlVv8_4Iy0ft1nJ13J3mF1UQLaoxMq6Cuowj0Jiu05QglaKlPTAUjUU3GpqlulUSuBHQLvVls8Hs3I8T1zMvVX5CEoslcFHz5v2vWseTlr-rYS4326BYhwWfiVjH_irxqyBtRByPpRGUEwR13qQSZEJnZvxGcWVge1ZMn2Imc9dWLIhNjXzGj8GYYu1J7hFe8bMmcFmq4442Y`,
			// 	//'Accept': 'application/json',
    		// 	'Content-Type': 'application/x-www-form-urlencoded'
			// }
		});
		
		//  request = request.clone({
		//  	setHeaders: {
		// 		APIKey: '5E40CB7C-6BE6-4A15-90E6-AF2BD5561930'
		//  	}
		//  });
		// console.log('----request----');
		// console.log(request);
		// console.log('--- end of request---');

		return next.handle(request).pipe(
			tap(
				event => {
					 if (event instanceof HttpResponse) {
						// console.log('all looks good');
						// http response status code
						// console.log(event.status);
					}
				},
				error => {
					// debugger;
					// http response status code
					// console.log('----response----');
					// console.error('status code:');
					// tslint:disable-next-line:no-debugger
					if (error.status === 401) {
                        window.location.href = "/auth";
                    }
					//console.error(error.status);
					//console.error(error.message);
					// console.log('--- end of response---');
				}
			)
		);
	}
}
