import { Component, OnInit, ChangeDetectorRef} from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Dataservice } from '../../../core/auth';

@Component({
  selector: 'kt-add-radius',
  templateUrl: './add-radius.component.html',
  styleUrls: ['./add-radius.component.scss']
})
export class AddRadiusComponent implements OnInit {
  brandFlavor = localStorage.getItem('BrandFlavor');
  addRadiusForm : FormGroup;

  postCodeData :any;
  radiusData :any;

  // Spinner
  isLoading : boolean = false;
  alertMessage: any;

  // Boolean span
  isExtra :boolean = false;
  status: boolean = false;


  // https://perkssrestapidevelopment.azurewebsites.net/api/Config/getPostcodes/barhillspice
  // https://perkssrestapidevelopment.azurewebsites.net/api/Config/updatePostcodes/barhillspice

  constructor(
    private dataService: Dataservice,
    private ref: ChangeDetectorRef,
    private router : Router
  ) { }

  ngOnInit() {
    this.addRadiusForm = new FormGroup({
      fromDistance : new FormControl(),
      toDistance : new FormControl(),
      deliveryCharges : new FormControl(),
      minimumOrder : new FormControl(),
      chargesBelow : new FormControl(),
      freeCharges : new FormControl()
    });

    this.isLoading = true;
    this.dataService.get("Config/getPostcodes/"  + this.brandFlavor , []).subscribe(
      res =>{
        if(res["Info"]["Status"] == 200){
          this.postCodeData = res["Postcodes"];
          this.radiusData = res["PostcodeRadiusConfig"]
          // console.log(this.postCodeData)
          this.isLoading = false;
        }
        else{
          this.alertMessage = res["Info"]["Message"];
          this.isLoading = false;
          alert(this.alertMessage);
        }
        this.ref.detectChanges();

      });
      console.log(`this.isExtra : ${this.isExtra}`)
      console.log(`this.status : ${this.status}`)
      this.ref.detectChanges();
  }
  applyExtra(value: boolean){
    this.isExtra = value;
    console.log(`this.isExtra : ${this.isExtra}`)
  }
  changeStatus(value: boolean){
    this.status = value;
    console.log(`this.status : ${this.status}`)
  }
  setValues(){
    let params;
    params = {
      FromDistance : Number(this.addRadiusForm.get('fromDistance').value),
      ToDistance : Number(this.addRadiusForm.get('toDistance').value),
      DeliveryCharges : Number(this.addRadiusForm.get('deliveryCharges').value),
      MinimumOrderAmount : Number(this.addRadiusForm.get('minimumOrder').value),
      ChargesBelowMinimumOrderAmount : Number(this.addRadiusForm.get('chargesBelow').value),
      FreeChargesAbove : Number(this.addRadiusForm.get('freeCharges').value),
      ApplyExtraCharge : this.isExtra,
      IsActive : this.status
    }
    console.log("parmas")
    console.log(params)
    this.radiusData.push(params);
    console.log("this.radiusData")
    console.log(this.radiusData)

    // 
    params = {
      PostcodeConfigType : true,
      Postcodes : this.postCodeData,
      PostcodeRadiusConfig : this.radiusData
    }
    this.isLoading = true;
    this.dataService.put("Config/updatePostcodes/"  + this.brandFlavor , params).subscribe(
      res =>{
        if(res["Info"]["Status"] == 200){
          // console.log(this.postCodeData)
          this.alertMessage = res["Info"]["Message"];
          this.isLoading = false;
          alert(this.alertMessage);
        }
        else{
          this.alertMessage = res["Info"]["Message"];
          this.isLoading = false;
          alert(this.alertMessage);
        }
        this.ref.detectChanges();

      });
      this.reloadCurrentRoute();
      
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }

}
