<h2 mat-dialog-title style="text-align: center;padding-top: 3%;">Edit Time Slot</h2>
<div class="mat-typography">
    <div class="container" style="padding: 8%;padding-top: 0%;
    padding-bottom: 0%;">
        <form> 
            <div class ="row">
                <div class="col-12">
                    <label for="input_starttime">Start</label>
                </div>
                <div class="col-4">
                    <mat-form-field appearance="fill">
                        <mat-label>Hours</mat-label>
                        <mat-select [(value)]="startHour">
                          <mat-option *ngFor="let hour of hoursArray" [value]="hour" >
                            {{hour}}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field appearance="fill">
                        <mat-label>Minutes</mat-label>
                        <mat-select [(value)]="startMin">
                          <mat-option *ngFor="let min of minArray" [value]="min">
                            {{min}}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field appearance="fill">
                        <mat-select [(value)]="startAm">
                          <mat-option *ngFor="let am of amArray" [value]="am">
                            {{am}}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
            <!-- Ends -->
            <div class ="row">
                <div class="col-12">
                    <label for="input_starttime">End</label>
                </div>
                
                <div class="col-4">
                    <mat-form-field appearance="fill">
                        <mat-label>Hours</mat-label>
                        <mat-select [(value)]="endHour">
                          <mat-option *ngFor="let hour of hoursArray" [value]="hour">
                            {{hour}}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field appearance="fill">
                        <mat-label>Minutes</mat-label>
                        <mat-select [(value)]="endMin">
                          <mat-option *ngFor="let min of minArray" [value]="min">
                            {{min}}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field appearance="fill">
                        <mat-select [(value)]="endAm">
                          <mat-option *ngFor="let am of amArray" [value]="am">
                            {{am}}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> 

            </div>
            
        </form>
        
    </div>
</div>
<div align="center">
    <button class="btn btn-primary btn-elevate kt-login__btn-primary" style="margin-left:5px;border-radius: 50px;"
         cdkFocusInitial (click)="setValues()">
        Update Time
    </button>
    <!-- [mat-dialog-close]="true" cdkFocusInitial -->
    <button class="btn btn-primary btn-elevate kt-login__btn-primary" mat-dialog-close
        style="margin-left:5px;border-radius: 50px;">
        Cancel
    </button>


</div>
<div class="overlay" *ngIf="isLoading">
    <div class="center">
        <mat-spinner [diameter]="200"></mat-spinner>
    </div>
</div>