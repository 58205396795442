// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	isMockEnabled: true, // You have to switch this, when your real back-end is done
	authTokenKey: "authce9d77b308c149d5992a80073637e4d5",
	apiURL: "https://dev-perkss.azurewebsites.net/api/",
	API_USERS_URL: "https://dev-perkss.azurewebsites.net/Token",

	firebase: {
		apiKey: "AIzaSyDqmt9iv1JG2B5p2L1lbWdvET5zVQA82aA",
		authDomain: "perkss-eb376.firebaseapp.com",
		databaseURL: "https://perkss-eb376.firebaseio.com",
		projectId: "perkss-eb376",
		storageBucket: "perkss-eb376.appspot.com",
		messagingSenderId: "510590324817",
		appId: "1:510590324817:web:d6edfaa6b4c88a27dfe870",
		measurementId: "G-EG7Y71FCJN",
	},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
