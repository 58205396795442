import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
// Store
import { Store } from "@ngrx/store";
import { AppState } from "../../reducers";
import { Login } from "../_actions/auth.actions";
import { loginUser } from "../_models/user.model";

@Injectable()
export class Dataservice {
	public isDevelopment: boolean;
	public isNewlyLogin: boolean;

	constructor(
		private httpclient: HttpClient,
		private store: Store<AppState>
	) {
		if (environment.production) {
			this.isDevelopment = true;
		} else {
			this.isDevelopment = false;
		}
	}
	getRequest(url, header) {
		return this.httpclient.get(environment.apiURL + url, {
			headers: header,
		});
	}

	get = function (url: string, data: any) {
		const httpHeaders = new HttpHeaders({
			Authorization: "bearer " + localStorage.getItem("token"),
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
		});
		let params = "";
		Object.keys(data).forEach((key, value) => {
			params += "/" + value;
		});
		return this.httpclient.get(environment.apiURL + url + params, {
			headers: httpHeaders,
		});
	};
	delete = function (url: string, data: any) {
		const httpHeaders = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: "bearer " + localStorage.getItem("token"),
		});

		return this.httpclient.delete(environment.apiURL + url, data, {
			headers: httpHeaders,
		});
	};

	post = function (url: string, data: any) {
		const httpHeaders = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: "bearer " + localStorage.getItem("token"),
		});
		return this.httpclient.post(environment.apiURL + url, data, {
			headers: httpHeaders,
		});
	};
	put = function (url: string, data: any) {
		const httpHeaders = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: "bearer " + localStorage.getItem("token"),
		});

		return this.httpclient.put(environment.apiURL + url, data, {
			headers: httpHeaders,
		});
	};

	postImage = function (url: string, data: any) {
		const httpHeaders = new HttpHeaders({
			//'Content-Type':'multipart/form-data',
			Authorization: "bearer " + localStorage.getItem("token"),
		});
		return this.httpclient.post(environment.apiURL + url, data, {
			headers: httpHeaders,
		});
	};
}
