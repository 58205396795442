import { ReservationModule } from './views/pages/reservation/reservation.module';
// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth
import { AuthGuard } from './core/auth';

const routes: Routes = [
	{ path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },

	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'dashboard',
				loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
			},
			{
				path: 'prevpayment-dashboard',
				loadChildren: () => import('./views/pages/payment-dashboard/payment-dashboard.module').then(m => m.PaymentDashboardModule),
			},
			{
				path: 'payment-dashboard',
				loadChildren: () => import('./views/pages/dashboard/payment-dashboard-new/payment-dashboard-new.module').then(m => m.PaymentDashboardNewModule),
			},
			{
				path: 'balancesummary',
				loadChildren: () => import('./views/pages/dashboard/balance-summary/balance-summary.module').then(m => m.BalanceSummaryModule),
			},
			{
				path: 'mealsummary',
				loadChildren: () => import('./views/pages/dashboard/meal-summary-dashboard/meal-summary-dashboard.module').then(m => m.MealSummaryDashboardModule),
			},
			{
				path: 'orderreport',
				loadChildren: () => import('./views/pages/dashboard/order-report-dashboard/order-report-dashboard.module').then(m => m.OrderReportDashboardModule),
			},
			{
				path: "addCustomer",
				loadChildren: () => import("./views/pages/addCustomer/addCustomer.module").then(m => m.AddCustomerModule)
			},
			// 
			{
				path: "customer",
				loadChildren: () => import("./views/pages/customer/customer.module").then(m => m.CustomerModule)
			},
			{
				path: "addnewcustomer",
				loadChildren: () => import("./views/pages/customer/sub-components/add-customer/add-customer.module").then(m => m.AddCustomerModule)
			},
			{
				path: "customerdetail",
				loadChildren: () => import("./views/pages/customer-detail/customer-detail.module").then(m => m.CustomerDetailModule)
			},
			{
				path: "customerpoint",
				loadChildren: () => import("./views/pages/customer-points/customer-points.module").then(m => m.CustomerPointsModule)
			},
			{
				path: "customerbalance",
				loadChildren: () => import("./views/pages/customer-balance/customer-balance.module").then(m => m.CustomerBalanceModule)
			},
			{
				path: 'advancesearch',
				loadChildren: () => import('./views/pages/advancesearch/advancesearch.module').then(m => m.AdvancesearchModule),
			},
			{
				path: 'users',
				loadChildren: () => import('./views/pages/users/users.module').then(m => m.UsersModule),
			},
			{
				path: 'products',
				loadChildren: () => import('./views/pages/products/products.module').then(m => m.ProductsModule),
			},
			{
				path: 'outlets',
				loadChildren: () => import('./views/pages/outlets/outlets.module').then(m => m.OutletsModule),
			},
			{
				path: 'orders-old',
				loadChildren: () => import('./views/pages/orders/orders.module').then(m => m.OrdersModule),
			},
			{
				path: 'orders',
				loadChildren: () => import('./views/pages/order-new/order-new.module').then(m => m.OrderNewModule),
			},
			{
				path: 'orders/printrecipt',
				loadChildren: () => import('./views/pages/printrecipt/printrecipt.module').then(m => m.PrintreciptModule),
			},
			{
				path: 'order-detail',
				loadChildren: () => import('./views/pages/order-detail/order-detail.module').then(m => m.OrderDetailModule),
			},
			{
				path: 'orderdetail/:id',
				loadChildren: () => import('./views/pages/get-order-detail/get-order-detail.module').then(m => m.GetOrderDetailModule),
			},
			{
				path: 'searchmember',
				loadChildren: () => import('./views/pages/searchmember/searchmember.module').then(m => m.SearchMemberModule),
			},
			{
				path: 'loyaltyprofile',
				loadChildren: () => import('./views/pages/loyaltyprofile/loyaltyprofile.module').then(m => m.LoyaltyProfileModule),
			},
			{
				path: 'searchresult',
				loadChildren: () => import('./views/pages/searchresult/search-result.module').then(m => m.SearchResultModule),
			},
			{
				path: 'customerdashboard',
				loadChildren: () => import('./views/pages/dashboard/customer-dashboard-new/customer-dashboard-new.module').then(m => m.CustomerDashboardNewModule),
			},
			{
				path: 'salesdashboard',
				loadChildren: () => import('./views/pages/dashboard/sales-dashboard/sales-dashboard.module').then(m => m.SalesDashboardModule),
			},
			{
				path: 'reservation',
				loadChildren: () => import('./views/pages/reservation/reservation.module').then(m => m.ReservationModule),
			},
			// reservation/Booking Setting
			{
				path: 'bookingsettings',
				loadChildren: () => import('./views/pages/booking-settings/booking-settings/booking-settings.module').then(m => m.BookingSettingsModule),
			},
			// Book General
			{
				path: 'bookgeneral',
				loadChildren: () => import('./views/pages/booking-settings/book-general-settings/book-general-settings.module').then(m => m.BookGeneralSettingsModule),
			},
			// Book General
			{
				path: 'bookgeneral',
				loadChildren: () => import('./views/pages/booking-settings/book-general-settings/book-general-settings.module').then(m => m.BookGeneralSettingsModule),
			},
			// Book Printer
			{
				path: 'bookprinter',
				loadChildren: () => import('./views/pages/booking-settings/book-printer-settings/book-printer-settings.module').then(m => m.BookPrinterSettingsModule),
			},
			//Book Slot
			{
				path: 'bookslot',
				loadChildren: () => import('./views/pages/booking-settings/book-slot-settings/book-slot-settings.module').then(m => m.BookSlotSettingsModule),
			},
			//Book Holiday
			{
				path: 'bookholiday',
				loadChildren: () => import('./views/pages/booking-settings/book-holiday-settings/book-holiday-settings.module').then(m => m.BookHolidaySettingsModule),
			},
			// reservation/Booking Setting Ends
			{
				path: 'reservation-edit',
				loadChildren: () => import('./views/pages/reservation-edit/reservation-edit.module').then(m => m.ReservationEditModule),
			},
			{
				path: 'reservation-add',
				loadChildren: () => import('./views/pages/reservation-add/reservation-add.module').then(m => m.ReservationAddModule),
			},
			{
				path: 'reservation-config',
				loadChildren: () => import('./views/pages/reservation-config/reservation-config.module').then(m => m.ReservationConfigModule),
			},
			{
				path: 'reserve-detail',
				loadChildren: () => import('./views/pages/reserve-detail/reserve-detail.module').then(m => m.ReserveDetailModule),
			},
			{
				path: 'history',
				loadChildren: () => import('./views/pages/history/history.module').then(m => m.HistoryModule),
			},
			{
				path: 'booking-history',
				loadChildren: () => import('./views/pages/reservation-history/reservation-history.module').then(m => m.ReservationHistoryModule),
			},
			{
				path: 'orderhistory',
				loadChildren: () => import('./views/pages/order-history/order-history.module').then(m => m.OrderHistoryModule),
			},
			{
				path: 'reward-configuration',
				loadChildren: () => import('./views/pages/reward-configuration/reward-configuration.module').then(m => m.RewardConfigurationModule),
			},
			// Gift Card
			{
				path: 'giftcard',
				loadChildren: () => import('./views/pages/gift-card/gift-card.module').then(m => m.GiftCardModule),
			},
			{
				path: 'giftcarddetail',
				loadChildren: () => import('./views/pages/gift-card-view/gift-card-view.module').then(m => m.GiftCardViewModule),
			},
			// capmaigns
			{
				path: 'campaign',
				loadChildren: () => import('./views/pages/Campaign/Campaign.module').then(m => m.CampaignModule),
			},
			{
				path: 'campaigndeal',
				loadChildren: () => import('./views/pages/campaign-deal/campaign-deal.module').then(m => m.CampaignDealModule),
			},
			{
				path: 'addcampaigndeal',
				loadChildren: () => import('./views/pages/campaign-add-deal/campaign-add-deal.module').then(m => m.CampaignAddDealModule),
			},
			{
				path: 'editcampaigndeal',
				loadChildren: () => import('./views/pages/campaign-edit-deal/campaign-edit-deal.module').then(m => m.CampaignEditDealModule),
			},
			{
				path: 'campaignevent',
				loadChildren: () => import('./views/pages/campaign-event/campaign-event.module').then(m => m.CampaignEventModule),
			},
			{
				path: 'campaignannouncement',
				loadChildren: () => import('./views/pages/campaign-announcement/campaign-announcement.module').then(m => m.CampaignAnnouncementModule),
			},
			{
				path: 'campaignnews',
				loadChildren: () => import('./views/pages/campaign-news/campaign-news.module').then(m => m.CampaignNewsModule),
			},
			{
				path: 'campaignnotification',
				loadChildren: () => import('./views/pages/campaign-notification/campaign-notification.module').then(m => m.CampaignNotificationModule),
			},
			{
				path: 'addcampaignnotification',
				loadChildren: () => import('./views/pages/campaign-add-notification/campaign-add-notification.module').then(m => m.CampaignAddNotificationModule),
			},
			{
				path: 'editcampaignnotification',
				loadChildren: () => import('./views/pages/campaign-edit-notification/campaign-edit-notification.module').then(m => m.CampaignEditNotificationModule),
			},
			// End capmaigns
			{
				path: 'settings',
				loadChildren: () => import('./views/pages/settings/settings.module').then(m => m.SettingsModule),
			},
			// Recipe View
			{
				path: 'viewrecipe',
				loadChildren: () => import('./views/pages/view-recipe/view-recipe.module').then(m => m.ViewRecipeModule),
			},
			{
				path: 'addrecipe',
				loadChildren: () => import('./views/pages/add-recipe/add-recipe.module').then(m => m.AddRecipeModule),
			},
			{
				path: 'editrecipe',
				loadChildren: () => import('./views/pages/edit-recipe/edit-recipe.module').then(m => m.EditRecipeModule),
			},
			{
				path: 'editrecipeoption',
				loadChildren: () => import('./views/pages/edit-recipe-option/edit-recipe-option.module').then(m => m.EditRecipeOptionModule),
			},

			// Recipe view ends

			// Products View
			{
				path: 'viewproduct',
				loadChildren: () => import('./views/pages/view-products/view-product/view-product.module').then(m => m.ViewProductModule),
			},
			{
				path: 'addproduct',
				loadChildren: () => import('./views/pages/view-products/add-product/add-product.module').then(m => m.AddProductModule),
			},
			{
				path: 'editproduct',
				loadChildren: () => import('./views/pages/view-products/edit-product/edit-product.module').then(m => m.EditProductModule),
			},

			// Products View ends


			//----------- Order Settings

			{
				path: 'ordersettings',
				loadChildren: () => import('./views/pages/order-settings/order-settings.module').then(m => m.OrderSettingsModule),
			},
			// Promo Settings
			{
				path: 'promocode',
				loadChildren: () => import('./views/pages/promocode/promocode.module').then(m => m.PromocodeModule),
			},
			{
				path: 'addpromocode',
				loadChildren: () => import('./views/pages/add-promocode/add-promocode.module').then(m => m.AddPromocodeModule),
			},
			{
				path: 'editpromocode',
				loadChildren: () => import('./views/pages/edit-promocode/edit-promocode.module').then(m => m.EditPromocodeModule),
			},
			// Promo Settings Ends

			// Discount Setting
			{
				path: 'discount',
				loadChildren: () => import('./views/pages/discount/discount.module').then(m => m.DiscountModule),
			},
			{
				path: 'adddiscount',
				loadChildren: () => import('./views/pages/add-discount/add-discount.module').then(m => m.AddDiscountModule),
			},
			{
				path: 'editdiscount',
				loadChildren: () => import('./views/pages/edit-discount/edit-discount.module').then(m => m.EditDiscountModule),
			},
			// Discount Setting Ends 
			// Radius Setting
			{
				// path: 'radius',
				path: 'deliveryarea',
				loadChildren: () => import('./views/pages/radius/radius.module').then(m => m.RadiusModule),
			},
			// Printer Setting
			{
				path: 'orderprinter',
				loadChildren: () => import('./views/pages/printer-setting/printer-setting.module').then(m => m.PrinterSettingModule),
			},
			// Order General Setting
			{
				path: 'ordergeneral',
				loadChildren: () => import('./views/pages/order-general-setting/order-general-setting.module').then(m => m.OrderGeneralSettingModule),
			},
			// Timing Setting
			{
				// path: 'timingsetting',
				path: 'orderslot',
				loadChildren: () => import('./views/pages/timing-setting/timing-setting.module').then(m => m.TimingSettingModule),
			},


			//-------------- Order Settings Ends


			{
				path: 'mail',
				loadChildren: () => import('./views/pages/apps/mail/mail.module').then(m => m.MailModule),
			},
			{
				path: 'ecommerce',
				loadChildren: () => import('./views/pages/apps/e-commerce/e-commerce.module').then(m => m.ECommerceModule),
			},
			// {
			// 	path: 'ngbootstrap',
			// 	loadChildren: () => import('./views/pages/ngbootstrap/ngbootstrap.module').then(m => m.NgbootstrapModule),
			// },
			{
				path: 'material',
				loadChildren: () => import('./views/pages/material/material.module').then(m => m.MaterialModule),
			},
			{
				path: 'user-management',
				loadChildren: () => import('./views/pages/user-management/user-management.module').then(m => m.UserManagementModule),
			},
			{
				path: 'wizard',
				loadChildren: () => import('./views/pages/wizard/wizard.module').then(m => m.WizardModule),
			},
			// {
			// 	path: 'builder',
			// 	loadChildren: () => import('./views/theme/content/builder/builder.module').then(m => m.BuilderModule),
			// },
			// Phone Order
			{
				path: 'phoneorder',
				loadChildren: () => import('./views/pages/phone-order-components/phone-order/phone-order.module').then(m => m.PhoneOrderModule),
			},
			{
				path: 'pomain',
				loadChildren: () => import('./views/pages/phone-order-components/phoneorder-main/phoneorder-main.module').then(m => m.PhoneorderMainModule),
			},
			{
				path: 'guestcheckout',
				loadChildren: () => import('./views/pages/phone-order-components/po-guest-checkout/po-guest-checkout.module').then(m => m.PoGuestCheckoutModule),
			},
			// Phone Order ends
			// Block Customer
			{
				path: 'blockcustomer',
				loadChildren: () => import('./views/pages/customer/sub-components/block-customer/block-customer.module').then(p => p.BlockCustomerModule),
			},
			// HKS
			{
				path: 'hks',
				loadChildren: () => import('./views/pages/hks-products/hks-products.module').then(p => p.HksProductsModule)
			},
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					type: 'error-v6',
					code: 403,
					title: '403... Access forbidden',
					desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator',
				},
			},
			{ path: 'error/:type', component: ErrorPageComponent },
			{ path: '', redirectTo: 'advancesearch', pathMatch: 'full' },
			{ path: '**', redirectTo: 'advancesearch', pathMatch: 'full' },
		],
	},

	{ path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
