import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ShareDataService } from './../../../service/share-data.service';
import { AuthNotice, Dataservice } from '../../../core/auth';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'kt-add-recipe-option-form',
  templateUrl: './add-recipe-option-form.component.html',
  styleUrls: ['./add-recipe-option-form.component.scss']
})
export class AddRecipeOptionFormComponent implements OnInit {

  getRecipeId: any;
  getCategoryId: any;
  brandId = localStorage.getItem('BrandID');
  getExtraId: any;
  getExtraArray: any;
  getExtraOptionId: any;



  // Form
  addOptionForm: FormGroup;
  // 
  newElement: any;
  nextsplitArray: any;
  isDataUploaded: boolean = false;
  isNewlyAdded : boolean;

  // Spinner
  isLoading: boolean = false;
  alertMessage: any;

  // Image
  url: any;
  getImage: boolean = false;
  // Submission
  imageUrl: any;
  fileToUpload: File = null;

  constructor(
    private sharedata: ShareDataService,
    private dataService: Dataservice,
    private ref: ChangeDetectorRef,
    private router: Router,
    public dialogRef: MatDialogRef<AddRecipeOptionFormComponent>
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.getRecipeId = this.sharedata.RecipeId;
    this.getCategoryId = this.sharedata.categoryId;
    this.getExtraId = this.sharedata.extrasId;
    this.getExtraOptionId = this.sharedata.extraOptionId;
    this.getExtraArray = this.sharedata.extraArray;
    this.isNewlyAdded = this.sharedata.isNewlyAdded;

    this.addOptionForm = new FormGroup({
      extraTitle: new FormControl(),
      extraPrice: new FormControl(),
    });
    this.isLoading = false;



  }

  // setValues(){
  //   this.addOptionForm.controls['extraTitle'].setValue(this.getExtraName);
  //   this.addOptionForm.controls['extraPrice'].setValue(this.getExtraPrice);
  // }

  onSubmit() {
    console.log("Submit Called");
    // 
    if(this.isNewlyAdded){
      this.getExtraArray.forEach(element => {
        if (element.extraId == this.getExtraId) {
          this.newElement = element;
          element.IsNewlyAdded = false;
          element.ShowOptionTitle = false;
        }
      });
      console.log("this.newElement")
      console.log(this.newElement)
      let newExtra = Object.assign({}, this.newElement)
      this.getExtraArray.pop();
      this.isLoading = true;
      if (this.fileToUpload != null) {
        console.log("fileToUpload");
        const formData: FormData = new FormData();
        formData.append('fileKey', this.fileToUpload, this.fileToUpload.name);
        this.dataService.postImage('/UploadImage', formData).subscribe(
          res => {
            this.imageUrl = "https://giftclubimagestorage.blob.core.windows.net/images/" + res;
            console.log(`this.imageUrl:  ${this.imageUrl}`);
  
            newExtra.Title = this.addOptionForm.get('extraTitle').value;
            newExtra.Price = this.addOptionForm.get('extraPrice').value;
            newExtra.Image = this.imageUrl
            newExtra.ShowOptionTitle = true;
            this.getExtraArray.push(newExtra);
            this.getExtraArray.forEach((element, index) => {
              element.extraId = index;
            });
            this.sharedata.extraArray = this.getExtraArray;
            this.isLoading = false;
            this.isDataUploaded = true;
            if (this.isDataUploaded) {
              this.dialogRef.close();
            }
            this.ref.detectChanges();
          }
        );
      }
      else {
        newExtra.Title = this.addOptionForm.get('extraTitle').value;
        newExtra.Price = this.addOptionForm.get('extraPrice').value;
        newExtra.Image = "";
        newExtra.ShowOptionTitle = true;
        this.getExtraArray.push(newExtra);
       
        this.getExtraArray.forEach((element, index) => {
          element.extraId = index;
        });
        this.sharedata.extraArray = this.getExtraArray;
        this.isLoading = false;
        this.isDataUploaded = true;
        if (this.isDataUploaded) {
          this.dialogRef.close();
        }
        this.ref.detectChanges();
      }
      console.log("NEWW this.getExtraArray")
      console.log(this.getExtraArray)
    }
    else{
      this.getExtraArray.forEach(element => {
        if (element.extraId == this.getExtraId) {
          this.newElement = element;
          element.IsNewlyAdded = false;
          element.ShowOptionTitle = false;
        }
      });
      this.nextsplitArray = this.getExtraArray.splice(Number(this.getExtraId));
      // 
      this.sharedata.isExtraChanges = true;
      let newExtra = Object.assign({}, this.newElement)
      // console.log(newExtra)
      this.isLoading = true;
  
      if (this.fileToUpload != null) {
        console.log("fileToUpload");
        const formData: FormData = new FormData();
        formData.append('fileKey', this.fileToUpload, this.fileToUpload.name);
        this.dataService.postImage('/UploadImage', formData).subscribe(
          res => {
            this.imageUrl = "https://giftclubimagestorage.blob.core.windows.net/images/" + res;
            console.log(`this.imageUrl:  ${this.imageUrl}`);
  
            newExtra.Title = this.addOptionForm.get('extraTitle').value;
            newExtra.Price = this.addOptionForm.get('extraPrice').value;
            newExtra.Image = this.imageUrl
            newExtra.ShowOptionTitle = true;
            this.getExtraArray.push(newExtra);
            this.getExtraArray = this.getExtraArray.concat(this.nextsplitArray);
            this.getExtraArray.forEach((element, index) => {
              element.extraId = index;
            });
            this.sharedata.extraArray = this.getExtraArray;
            this.isLoading = false;
            this.isDataUploaded = true;
            if (this.isDataUploaded) {
              this.dialogRef.close();
            }
            this.ref.detectChanges();
          }
        );
      }
      else {
        newExtra.Title = this.addOptionForm.get('extraTitle').value;
        newExtra.Price = this.addOptionForm.get('extraPrice').value;
        newExtra.Image = "";
        newExtra.ShowOptionTitle = true;
        this.getExtraArray.push(newExtra);
        this.getExtraArray = this.getExtraArray.concat(this.nextsplitArray);
        this.getExtraArray.forEach((element, index) => {
          element.extraId = index;
        });
        this.sharedata.extraArray = this.getExtraArray;
        this.isLoading = false;
        this.isDataUploaded = true;
        if (this.isDataUploaded) {
          this.dialogRef.close();
        }
        this.ref.detectChanges();
      }
    }
   



  }
  changeroute(url) {
    this.router.navigateByUrl('/' + url);
  }
  // closeDialogue() {

  // }

  public addFile(event: any, files) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.url = event.target.result;
        this.ref.detectChanges();
      }
      reader.readAsDataURL(event.target.files[0]);
      this.fileToUpload = files.item(0);
      console.log("FILE:: ");
      console.log(this.fileToUpload);
      this.ref.detectChanges();
      // this.ref.detectChanges();
      this.getImage = true;
    }

  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46 ) {
      return false;
    }
    return true;
  }




}
